import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";
import { all } from "redux-saga/effects";

import getRoutes from "./getRoutes";
import getReducers from "./getReducers";
import getMiddleware from "./getMiddleware";
import createStore from "./createStore";

/**
 *
 * @param modules {Array}
 * @param appOptions {Object}
 * @param appOptions.basename {String} route basename ex: en/, en/app
 * @param appOptions.initState {Object}
 * @return {{store: *, routes: *, history: *}}
 */
export default (modules = [], appOptions) => {
	const options = {
		basename: "",
		initState: {},
		...appOptions
	};
	const history = createBrowserHistory({ basename: options.basename });
	const sagaMiddleware = createSagaMiddleware();

	const routes = getRoutes(modules);
	const reducers = getReducers(modules);
	const middleware = getMiddleware(modules, routerMiddleware(history), sagaMiddleware);
	const store = createStore({
		middleware,
		reducers,
		initState: options.initState || {},
		history
	});

	sagaMiddleware.run(getRootSaga(modules));

	return { store, routes, history };

	// private methods...

	function getRootSaga() {
		const sagas = modules.reduce((accumulate, module) => {
			if (module.getSagas) {
				return accumulate.concat(module.getSagas());
			}

			return accumulate;
		}, []);

		return function*() {
			yield all(sagas);
		};
	}
};
