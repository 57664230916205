import kkmApi from "./kkmApi";
import login from "./login";
import user from "./user";
import company from "./company";
import control from "./control";

export default http => {
	// Kkm API
	const api = http.addResource("api");

	kkmApi(api);

	// Site API
	const site = http.addResource("site");

	login(site);
	user(site);
	company(site);
	control(site);
};
