import React from "react";
import PropTypes from "prop-types";
import { STATUS, STATUS_LABEL } from "common/enums/kkm";

function TransactionStatus({ value }) {
	switch (value) {
		case STATUS.QUEUED:
			return <span className="badge badge-secondary">{STATUS_LABEL.QUEUED}</span>;
		case STATUS.PENDING:
			return <span className="badge badge-warning">{STATUS_LABEL.PENDING}</span>;
		case STATUS.COMPLETED:
			return <span className="badge badge-success">{STATUS_LABEL.COMPLETED}</span>;
		case STATUS.FAILED:
			return <span className="badge badge-danger">{STATUS_LABEL.FAILED}</span>;
		default:
			return <span className="f-12">не указан</span>;
	}
}

TransactionStatus.propTypes = {
	value: PropTypes.oneOf(Object.values(STATUS))
};

export default TransactionStatus;
