import React, { Component } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import NavGroup from "./NavGroup";

export default class index extends Component {
	static propTypes = {
		navigation: PropTypes.array
	};

	render() {
		const { navigation } = this.props;

		return (
			<div className="navbar-content datta-scroll">
				<PerfectScrollbar>
					<ul className="nav pcoded-inner-navbar">
						{navigation.map(item =>
							item.type === "group" ? <NavGroup key={item.id} group={item} /> : null
						)}
					</ul>
				</PerfectScrollbar>
			</div>
		);
	}
}
