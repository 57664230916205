import React from "react";

import "./Loading.css";

export default () => (
	<div className="lds-ring">
		<div />
		<div />
		<div />
		<div />
	</div>
);
