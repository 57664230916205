export default ({ offerAgreementLink }) => [
	{
		id: "kkm",
		title: "Кассы",
		type: "group",
		children: [
			{
				id: "kkmList",
				title: "Список касс",
				type: "item",
				icon: "feather icon-list",
				exact: true,
				url: "/kkm"
			},
			{
				id: "kkmGroup",
				title: "Группы касс",
				type: "item",
				icon: "feather icon-layers",
				exact: true,
				url: "/kkm-group"
			},
			{
				id: "registries",
				title: "Реестры",
				type: "item",
				icon: "feather icon-layers",
				exact: true,
				url: "/registries"
			}
		]
	},
	{
		id: "company",
		title: "Компании",
		type: "group",
		children: [
			{
				id: "companyChildren",
				title: "Дочерние компании",
				type: "item",
				icon: "feather icon-square",
				exact: true,
				url: "/company"
			}
		]
	},
	{
		id: "users",
		title: "Пользователи",
		type: "group",
		children: [
			{
				id: "usersList",
				title: "Список пользователей",
				type: "item",
				icon: "feather icon-users",
				exact: true,
				url: "/users"
			}
		]
	},
	{
		id: "support",
		title: "Поддержка",
		type: "group",
		children: [
			{
				id: "docs",
				title: "Документация",
				type: "item",
				icon: "feather icon-book",
				external: true,
				url: "/static/api_docs.pdf"
			},
			{
				id: "files",
				title: "Договор оферта",
				type: "item",
				icon: "feather icon-file",
				external: true,
				url: offerAgreementLink
			}
		]
	}
];
