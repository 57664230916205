import { createSelector } from "reselect";

const appSelector = state => state.app;

export const isAppReadySelector = createSelector(
	appSelector,
	state => state.isAppReady
);
export const isAuthUserSelector = createSelector(
	appSelector,
	state => state.isAuthUser
);
