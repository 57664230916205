import api from "common/api/index";

// PUT /site/user
export const putUser = ({ currentCompanyId }) => {
	return api
		.site()
		.user()
		.put({ currentCompanyId });
};

// PUT /site/user/profile
export const putUserProfile = ({ query }) => {
	return api
		.site()
		.user()
		.profile()
		.put(query);
};

// POST /site/user/password
export const postUserPassword = ({ query }) => {
	return api
		.site()
		.user()
		.password()
		.post(query);
};

// POST /site/user/password/restore
export const postUserPasswordRestore = ({ email }) => {
	return api
		.site()
		.user()
		.password()
		.restore()
		.post({ email });
};
