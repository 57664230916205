import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import DatePicker from "components/ui/DatePicker";
import hocValidateField from "./helpers/hocValidateField";

const ADatePicker = hocValidateField(DatePicker);
const DatePickerField = props => <Field {...props} component={ADatePicker} />;

DatePickerField.propTypes = {
	name: PropTypes.string.isRequired
};

export default DatePickerField;
