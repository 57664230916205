import React, { Component } from "react";
import PropTypes from "prop-types";
import Form from "antd/lib/form";

export default class extends Component {
	static displayName = "Form";

	static propTypes = {
		layout: PropTypes.oneOf(["horizontal", "vertical", "inline"]),
		onSubmit: PropTypes.func
	};

	static defaultProps = {
		layout: "vertical"
	};

	onSubmit = e => {
		const { onSubmit } = this.props;

		onSubmit(e);

		e.preventDefault();
		e.stopPropagation();
	};

	render() {
		const props = this.props;
		return <Form {...props} onSubmit={this.onSubmit} />;
	}
}
