import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import Select from "components/ui/Select";
import hocValidateField from "./helpers/hocValidateField";

const ASelect = hocValidateField(Select);
const SelectField = props => <Field {...props} component={ASelect} />;

SelectField.propTypes = {
	name: PropTypes.string.isRequired,
	options: PropTypes.array
};

export default SelectField;
