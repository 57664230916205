import { isEmpty } from "../validators";

export const isValidNumber = val => {
	if (isEmpty(val)) return true;
	return /^(0(\.|,)\d+|[1-9]+[0-9]*((\.|,)\d+)?|0)$/.test(+val);
};

export const parseNumber = value => {
	if (isEmpty(value)) return null;
	let viewValue = value;
	if (value.replace) {
		viewValue = cleanValue(value);
		if (isValidNumber(viewValue)) {
			const num = parseFloat(viewValue.replace(",", "."));
			if (!isNaN(num)) return num;
		}
		return viewValue;
	}
	return viewValue;
};

export const cleanValue = (val, float = true) => {
	let result = val.replace(/[^0-9\.,]+/g, "").replace(",", ".");

	const commaPos = result.indexOf(".");

	if (float) {
		if (commaPos > -1) {
			const output = result.split(".");
			result = output.shift() + "." + output.join("");
		}
	} else {
		//берем только целую часть
		if (commaPos >= 0) {
			result = result.substr(0, commaPos);
		}
	}

	return result;
};

export const parseFloatOrNull = val => {
	const result = parseFloat(cleanValue(val));
	return isNaN(result) ? null : result;
};

export const calculateStart = (start, originValue, formattedValue) => {
	const substr = originValue.substring(0, start).replace(/ /g, "");
	let regex = "^";

	for (let i = 0, len = substr.length; i < len; i++) {
		regex += substr[i] + " {0,1}";
	}
	const match = new RegExp(regex).exec(formattedValue);
	return (match && match[0].length) || 0;
};

export const noZero = str => {
	return str && str.replace ? str.replace(/0*/g, "").replace(/,*/, "").length == 0 : false;
};

export const trimValidLength = (str, char, round) => {
	let resultStr = str;
	let lengthAfterChar = 0;

	if (str.lastIndexOf(char) >= 0) {
		lengthAfterChar = str.length - str.lastIndexOf(char) - 1;
	}

	let trimLength = lengthAfterChar - round; //сколько лишних символов нужно отрезать с конца

	if (trimLength > 0) {
		resultStr = str.substr(0, str.length - trimLength);
	}

	return resultStr;
};

export const rand = (min = 0, max = 1000) => {
	return parseInt(Math.random() * (max - min + 1) + min);
};

export const sortNumberByAsc = (arr = []) => {
	return arr.sort((a, b) => {
		if (a < b) return -1;
		if (a > b) return 1;
		return 0;
	});
};

export const formatVat = value => {
	if (isEmpty(value)) {
		return value;
	}
	const number = parseNumber(value);
	return `${number}%`;
};
