import { ANONYMOUS_PAGES, SIGN_IN_PAGE } from "common/enums/app";
import { redirect } from "../../common/helpers/location";

const AuthorizationKeyBasic = "Authorization";

export default () => {
	return {
		request,
		response,
		responseError
	};

	function request(config) {
		if (config.Authorization) {
			config.headers[AuthorizationKeyBasic] = config.Authorization;
		}

		// чтобы при получении 401 в барузере не появлялся стандартный попап для авторизации
		config.headers["X-Requested-With"] = "XMLHttpRequest";

		return config;
	}

	function response(resp) {
		return resp;
	}

	function responseError(resp) {
		// если пришла 401 ошибка, не вовремя BasicAuth(вход в систему),
		// обнуляем токен и отправляем на авторизацию
		if (resp.response && resp.response.status === 401 && !resp.response.config.Authorization) {
			const href = window.location.href;
			if (href && ANONYMOUS_PAGES.filter(p => href.includes(p)).length === 0) {
				redirect(`${SIGN_IN_PAGE}`);
			}
		}

		return Promise.reject(resp);
	}
};
