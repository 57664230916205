import { combineReducers, compose, createStore } from "redux";
import { connectRouter } from "connected-react-router";

export default ({ middleware, reducers, initState, history }) => {
	const appReducers = combineReducers({
		router: connectRouter(history),
		...reducers
	});
	const allReducers = (state, action) => {
		let nextState = state;

		if (action.type === "@@core/CLEAR_APP") {
			nextState = undefined;
		}

		return appReducers(nextState, action);
	};

	let enhancer;

	if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
		enhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(...middleware);
	} else {
		enhancer = compose(...middleware);
	}

	return createStore(allReducers, initState, enhancer);
};
