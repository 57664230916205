/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import NavItem from "./NavItem";

export default class NavGroup extends Component {
	static propTypes = {
		group: PropTypes.shape({
			id: PropTypes.string,
			title: PropTypes.string,
			type: PropTypes.string,
			children: PropTypes.array
		})
	};

	get navChildren() {
		const { group } = this.props;

		if (group.children) {
			const groups = group.children;

			return groups.map(item => {
				switch (item.type) {
					case "collapse": // TODO dop-list
					case "item":
						return <NavItem key={item.id} item={item} />;
					default:
						return false;
				}
			});
		}

		return null;
	}

	render() {
		const { group } = this.props;

		return (
			<Fragment>
				{group.title && (
					<li key={group.id} className="nav-item pcoded-menu-caption">
						<label>{group.title}</label>
					</li>
				)}

				{this.navChildren}
			</Fragment>
		);
	}
}
