import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "common/validators";
import { cleanValue, trimValidLength, parseNumber, noZero } from "common/helpers/number";

import Input from "./Input";

export default class NumberInput extends Component {
	static propTypes = {
		float: PropTypes.bool,
		precision: PropTypes.number,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		onChange: PropTypes.func,
		maxLength: PropTypes.number
	};

	static defaultProps = {
		precision: 4
	};

	constructor(props) {
		super(props);

		this.state = {
			value: this.parseValue(props.value)
		};
	}

	componentDidMount() {
		const { value } = this.props;

		this.setState({
			value: this.parseValue(value)
		});
	}

	// TODO UNSAFE
	UNSAFE_componentWillReceiveProps(nextProps) {
		const { value } = this.state;

		if (nextProps && !this.isEqualValues(value, nextProps.value)) {
			this.setState({
				value: this.parseValue(nextProps.value)
			});
		}
	}

	isEqualValues = (prevValue, nextValue) => {
		const pVal = parseNumber(prevValue);
		const nVal = parseNumber(nextValue);

		return pVal === nVal || (pVal && noZero(pVal) && nVal === 0);
	};

	parseValue = value => {
		const { float, precision } = this.props;

		if (isEmpty(value)) {
			value = "";
		}

		if (!value.replace) {
			value = value.toString();
		}

		value = cleanValue(value, float);

		return float && precision ? trimValidLength(value, ".", precision) : value;
	};

	handleChange = e => {
		const { onChange } = this.props;
		const value = this.parseValue(e.target.value);
		e.target.value = value;
		onChange && onChange(e, value);
		this.setState({ value });
	};

	render() {
		const { ...props } = this.props;
		const { value } = this.state;

		delete props.value;
		delete props.float;
		delete props.precision;

		return <Input {...props} value={value} onChange={this.handleChange} />;
	}
}
