/* eslint-disable react/no-danger */

import "antd/lib/auto-complete/style";
import AutoComplete from "antd/lib/auto-complete";

import React, { Component } from "react";
import PropTypes from "prop-types";
import Input from "./Input";

const { Option } = AutoComplete;

export default class extends Component {
	static displayName = "AutoComplete";

	static propTypes = {
		isLoading: PropTypes.bool,
		pickOut: PropTypes.bool,
		noDataMess: PropTypes.string,

		value: PropTypes.string,
		dataSource: PropTypes.array,

		valueKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
		valueStr: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
		valueDesc: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

		onSelect: PropTypes.func,

		suffix: PropTypes.node
	};

	static defaultProps = {
		optionLabelProp: "text",
		noDataMess: ""
	};

	get dataSource() {
		const { isLoading, dataSource, noDataMess } = this.props;

		return dataSource
			.map(element => {
				const key = this.getKey(element);
				const value = this.getValue(element);
				const desc = this.getDesc(element);

				return (
					<Option key={key} text={value}>
						<div dangerouslySetInnerHTML={{ __html: this.pickOutValue(value) }} />
						{desc && (
							<div
								className="f-12 text-muted wid-max"
								dangerouslySetInnerHTML={{ __html: this.pickOutValue(desc) }}
							/>
						)}
					</Option>
				);
			})

			.concat(
				!isLoading && !dataSource.length && noDataMess
					? // prettier-ignore
					  [
						<Option key="no_data" disabled>
							<div className="f-12 text-muted wid-max text-center">{noDataMess}</div>
						</Option>
					]
					: []
			);
	}

	getKey = element => {
		const { valueKey } = this.props;
		return typeof valueKey === "function" ? valueKey(element) : element[valueKey];
	};

	getValue = element => {
		const { valueStr } = this.props;
		return typeof valueStr === "function" ? valueStr(element) : element[valueStr];
	};

	getDesc = element => {
		const { valueDesc } = this.props;
		return valueDesc ? (typeof valueDesc === "function" ? valueDesc(element) : element[valueDesc]) : null;
	};

	pickOutValue = search => {
		const { value, pickOut } = this.props;

		if (pickOut) {
			return ("" + value).split(" ").reduce((accum, item) => {
				if ("<span class='text-primary'></span>".includes(item)) return accum;

				return ("" + accum).replace(
					new RegExp(("" + item).replace(/\s/g, ""), "gi"),
					"<span class='text-primary'>$&</span>"
				);
			}, search);
		} else {
			return search;
		}
	};

	handleSelect = key => {
		const { dataSource, onSelect } = this.props;

		const element = dataSource.find(element => (this.getKey(element) === key ? element : false));
		const value = this.getValue(element);

		onSelect && onSelect(value, element);
	};

	renderPrefix = () => {
		const { isLoading } = this.props;

		return isLoading ? <i className="spinner-grow spinner-grow-sm" /> : <i className="feather icon-search" />;
	};

	render() {
		const { value, suffix, ...props } = this.props;

		return (
			<AutoComplete {...props} dataSource={this.dataSource} onSelect={this.handleSelect}>
				<Input value={value} prefix={this.renderPrefix()} suffix={suffix} />
			</AutoComplete>
		);
	}
}
