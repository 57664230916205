import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/appAction";

const initialState = {
	isAppReady: false,
	isAuthUser: false,

	isCollapseMenu: false,

	account: null
};
const actionHandlers = {
	[action.setAppReady]: createMutateReducer((draft, { isReady, account }) => {
		draft.isAppReady = isReady;
		draft.isAuthUser = !!account;
		draft.account = account;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
