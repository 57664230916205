import React from "react";
import { Link } from "react-router-dom";
import config from "../../../common/config";

export default () => (
	<div className="navbar-brand header-logo">
		<Link to="/" className="b-brand">
			<div className="b-bg">
				<i className="feather icon-trending-up" />
			</div>
			<span className="b-title">{config.companyName}</span>
		</Link>
	</div>
);
