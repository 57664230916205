import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { InputField, PhoneField } from "components/form";
import { validateEmail as vEmail, validateRequired } from "common/validators";
import CompanySelectField from "../../containers/CompanySelectField";

const validateEmail = value => (vEmail(value) ? undefined : "Электронная почта не верна");

export default class CpCompanyInfoItems extends Component {
	static propTypes = {
		isAccessUpdName: PropTypes.bool,
		selectParentId: PropTypes.shape({
			isDisabled: PropTypes.bool,
			isLoading: PropTypes.bool,
			options: PropTypes.array,
			onSearch: PropTypes.func
		}).isRequired,
		onBlurEmail: PropTypes.func,
		onBlurManagerPhone: PropTypes.func,
		form: PropTypes.string
	};

	handleBlurEmail = value => {
		const { onBlurEmail } = this.props;
		onBlurEmail && onBlurEmail(value);
	};

	handleBlurManagerPhone = value => {
		const { onBlurManagerPhone } = this.props;
		onBlurManagerPhone && onBlurManagerPhone(value);
	};

	render() {
		const { isAccessUpdName, form } = this.props;

		return (
			<Fragment>
				<div className="card-block">
					<div className="row justify-content-md-center">
						<div className="col-md-6">
							<InputField name="name" label="Наименование компании" disabled={!isAccessUpdName} />
						</div>
						<div className="col-md-6">
							<CompanySelectField
								form={form}
								name="parentId"
								type="owner"
								label="Родительская компания"
							/>
							{/*
							<SelectField
								name="parentId"
								label="Родительская компания"
								notFoundContent=""
								showSearch
								disabled={selectParentId.isDisabled}
								loading={selectParentId.isLoading}
								options={selectParentId.options}
								onSearch={selectParentId.onSearch}
								allowClear
							/>
							*/}
						</div>
						<div className="col-md-12">
							<InputField
								name="address"
								label="Адрес организации"
								textArea
								validate={[validateRequired]}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<InputField name="inn" label="ИНН" validate={[validateRequired]} />
						</div>
						<div className="col-md-4">
							<InputField name="ogrn" label="ОГРН" />
						</div>
						<div className="col-md-4">
							<InputField name="kpp" label="КПП" />
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<InputField name="managerName" label="ФИО руководителя" validate={[validateRequired]} />
						</div>
						<div className="col-md-4">
							<InputField
								name="managerPost"
								label="Должность руководителя"
								validate={[validateRequired]}
							/>
						</div>
						<div className="col-md-4">
							<PhoneField
								name="managerPhone"
								label="Телефон руководителя"
								validate={[validateRequired]}
								onBlur={this.handleBlurManagerPhone}
							/>
						</div>
					</div>
				</div>
				<div className="card-block">
					<div className="row">
						<div className="col-md-4">
							<PhoneField
								name="phone"
								label="Телефон огранизации"
								validate={[validateRequired]}
								onBlur={this.handleBlurManagerPhone}
							/>
						</div>
						<div className="col-md-4">
							<InputField
								name="email"
								label="Электронная почта огранизации"
								validate={[validateRequired, validateEmail]}
								onBlur={this.handleBlurEmail}
							/>
						</div>
						<div className="col-md-4">
							<InputField name="website" label="Сайт огранизации" />
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}
