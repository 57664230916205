import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import "antd/lib/switch/style";
import Switch from "antd/lib/switch";

const SIZE_EXTRA_CLASSES = {
	small: "_sm",
	large: "_lg"
};

export default class extends Component {
	static displayName = "Switch";

	static propTypes = {
		children: PropTypes.node,
		label: PropTypes.node,
		desc: PropTypes.node,
		className: PropTypes.string,

		swLabel: PropTypes.node,
		swDesc: PropTypes.node
	};

	render() {
		const { children, className, ...props } = this.props;
		const { size, label, swLabel, desc, swDesc } = props;
		const sizeExtraClass = SIZE_EXTRA_CLASSES[size];
		const _classname = cn("ant-switch-wrap", { [sizeExtraClass]: sizeExtraClass }, className);

		delete props.swLabel;
		delete props.swDesc;

		return (
			<div className={_classname}>
				{/* eslint-disable-next-line */}
				<label className="ant-switch-label">
					<Switch {...props} />
					{label || swLabel ? (
						<span className="ant-switch-text cursor-pointer p-l-10">{label || swLabel}</span>
					) : null}
				</label>
				{desc || swDesc ? (
					<div className="ant-switch-desc f-12">{desc || swDesc}</div>
				) : (
					children && <div className="ant-switch-inner">{children}</div>
				)}
			</div>
		);
	}
}
