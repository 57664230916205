import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";

export const withStoreProvider = () => Component => {
	const withStoreProviderHOC = ({ store, ...props }) => {
		return (
			<Provider store={store}>
				<Component {...props} />
			</Provider>
		);
	};

	withStoreProviderHOC.propTypes = {
		store: PropTypes.shape({}).isRequired
	};

	return withStoreProviderHOC;
};
