import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import "antd/lib/menu/style";
import Menu from "antd/lib/menu";

import "antd/lib/dropdown/style";
import Dropdown from "antd/lib/dropdown";

@withRouter
export default class UserComponent extends Component {
	static propTypes = {
		userInfo: PropTypes.shape({
			lastName: PropTypes.string,
			firstName: PropTypes.string,
			middleName: PropTypes.string
		}),
		currentCompany: PropTypes.shape({
			name: PropTypes.string
		}),
		onLogout: PropTypes.func,
		history: PropTypes.shape({
			push: PropTypes.func
		})
	};

	get overlay() {
		const { userInfo, currentCompany, onLogout } = this.props;

		return (
			<Menu>
				<Menu.Item className="user-name-menu">
					<span>{`${userInfo.lastName} ${userInfo.firstName} ${userInfo.middleName}`}</span>
					<div className="f-w-600 m-t-15">{currentCompany?.name}</div>
				</Menu.Item>

				<Menu.Divider />

				<Menu.Item onClick={this.handleMenuClick(`/settings`)}>
					<i className="feather icon-settings p-r-15" /> Настройки профиля
				</Menu.Item>
				<Menu.Item onClick={onLogout}>
					<i className="feather icon-log-out p-r-15" /> Выйти из системы
				</Menu.Item>
			</Menu>
		);
	}

	handleMenuClick = link => ({ domEvent }) => {
		this.handleStopEvent(domEvent);
		this.props.history.push(link);
	};

	handleStopEvent = e => {
		e?.stopPropagation();
		e?.preventDefault();
	};

	render() {
		const { userInfo } = this.props;

		return (
			<Dropdown overlay={this.overlay} placement="bottomRight">
				<span className="cursor-pointer" onClick={this.handleStopEvent}>
					<i className="feather icon-user p-r-15" />
					{`${userInfo.lastName} ${userInfo.firstName}`}
				</span>
			</Dropdown>
		);
	}
}
