import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import AmountInput from "components/ui/AmountInput";
import hocValidateField from "./helpers/hocValidateField";

const AAmountInput = hocValidateField(AmountInput);
const AmountField = props => <Field {...props} component={AAmountInput} />;

AmountField.propTypes = {
	name: PropTypes.string.isRequired
};

export default AmountField;
