/* eslint-disable react/no-children-prop */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Link } from "react-router-dom";
import cn from "classnames";
import NavIcon from "./NavIcon";
import NavBadge from "./NavBadge";

export default class NavItem extends Component {
	static propTypes = {
		item: PropTypes.shape({
			title: PropTypes.string,
			icon: PropTypes.string,
			target: PropTypes.bool,
			external: PropTypes.bool,
			url: PropTypes.string,
			className: PropTypes.string
		})
	};

	static defaultProps = {};

	render() {
		const { item } = this.props;
		let itemTitle = item.title;

		if (item.icon) {
			itemTitle = <span className="pcoded-mtext">{item.title}</span>;
		}

		return (
			<li className={item.className}>
				{item.external ? (
					<a className="nav-link" href={item.url} target="_blank" rel="noopener noreferrer">
						<NavIcon items={item} />
						{itemTitle}
					</a>
				) : (
					<Route
						exact
						path={item.url}
						children={({ match }) => (
							<Link to={item.url} className={cn("nav-link", { active: match })}>
								<NavIcon items={item} />

								{itemTitle}

								<NavBadge items={item} />
							</Link>
						)}
					/>
				)}
			</li>
		);
	}
}
