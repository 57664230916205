import "antd/lib/input/style";

import React, { Component } from "react";
import PropTypes from "prop-types";
import Input from "antd/lib/input";

export default class extends Component {
	static displayName = "Input";

	static propTypes = {
		textArea: PropTypes.bool,
		password: PropTypes.bool,
		search: PropTypes.bool,
		size: PropTypes.string
	};

	static defaultProps = {
		size: "small"
	};

	constructor(props) {
		super(props);
		this.input = React.createRef();
	}

	setFocus() {
		const { textArea, password } = this.props;

		if (textArea) {
			this.input?.current?.textAreaRef?.focus();
		} else if (password) {
			this.input?.current?.input?.focus();
		} else {
			this.input?.current?.input?.focus();
		}
	}

	render() {
		const { textArea, password, search, ...props } = this.props;

		if (textArea) {
			return <Input.TextArea ref={this.input} {...props} />;
		} else if (password) {
			return <Input.Password ref={this.input} {...props} />;
		} else if (search) {
			return <Input.Search ref={this.input} {...props} />;
		}

		return <Input ref={this.input} {...props} />;
	}
}
