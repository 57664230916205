import api from "common/api/index";
import * as mapper from "./mapper";

// POST /login
export const postCheckLogin = () => {
	return api
		.site()
		.login()
		.post()
		.then(res => mapper.login.toClient(res.data));
};

// POST /login
export const postLogin = token => {
	return api
		.site()
		.login()
		.post(undefined, {
			Authorization: `Basic ${token}`,
			headers: {
				"Cache-Control": "no-cache,no-store,must-revalidate,max-age=-1,private"
			}
		})
		.then(res => mapper.login.toClient(res.data));
};

// POST /logout
export const postLogout = () => {
	return api
		.site()
		.logout()
		.post()
		.then(res => res.data);
};
