import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "antd/lib/modal/style";
import Modal from "antd/lib/modal";

export default class MainPopup extends Component {
	static propTypes = {
		resolveClose: PropTypes.func,
		rejectClose: PropTypes.func,
		destroyOnClose: PropTypes.bool,
		size: PropTypes.oneOf(["small", "large", "xlarge", "xxlarge"]),
		footer: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
		className: PropTypes.string,
		visible: PropTypes.bool,
		onClose: PropTypes.func
	};

	static defaultProps = {
		okText: "OK",
		cancelText: "Cancel",
		centered: true,
		maskClosable: false,
		destroyOnClose: true
	};

	static getDerivedStateFromProps(nextProps) {
		if ("visible" in nextProps) {
			return { visible: nextProps.visible };
		}
		return null;
	}

	state = { visible: false };

	open = () => {
		this.handleVisibleChange(true);
	};

	close = () => {
		this.handleVisibleChange(false);
	};

	isOpen = () => {
		return this.state.visible;
	};

	handleVisibleChange = visible => {
		//если нет в пропсах то состояние внутреннее
		if (!("visible" in this.props)) {
			this.setState({ visible });
		}
	};

	handleRequestClose = () => {
		this.handleVisibleChange(false);
		const { onClose } = this.props;
		onClose && onClose();
	};

	render() {
		const { destroyOnClose, size, footer, className, ...props } = this.props;
		const { visible } = this.state;

		const styles = classNames(className, {
			_sm: size === "small",
			_lg: size === "large",
			_xlg: size === "xlarge",
			_xxlg: size === "xxlarge"
		});

		return (
			<Modal
				{...props}
				onOk={this.handleRequestClose}
				onCancel={this.handleRequestClose}
				visible={visible}
				destroyOnClose={destroyOnClose}
				className={styles}
				footer={footer || null}
			/>
		);
	}
}
