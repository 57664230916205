import React, { Component } from "react";
import HeaderContainer from "modules/app/containers/HeaderContainer";

export default class Index extends Component {
	render() {
		return (
			<header className="navbar pcoded-header navbar-expand-lg">
				<div className="collapse navbar-collapse">
					<HeaderContainer />
				</div>
			</header>
		);
	}
}
