import React from "react";
import PropTypes from "prop-types";
import Alert from "./Alert";

function PageError({ isShow, ...props }) {
	if (!isShow) {
		return null;
	}

	return <Alert type="error" {...props} />;
}

PageError.propTypes = {
	isShow: PropTypes.bool,
	message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	description: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

PageError.defaultProps = {
	message: "Произошла ошибка",
	description: "Попробуйте перезапустить страницу или обратиться в тех.поддержку"
};

export default PageError;
