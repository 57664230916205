import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import Input from "components/ui/Input";
import hocValidateField from "./helpers/hocValidateField";

const AInput = hocValidateField(Input);
const InputField = props => <Field {...props} component={AInput} />;

InputField.propTypes = {
	name: PropTypes.string.isRequired,
	textArea: PropTypes.bool,
	password: PropTypes.bool
};

export default InputField;
