import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import deferred from "common/helpers/deferred";
import MainPopup from "./MainPopup";

export default class Content extends Component {
	static propTypes = {
		className: PropTypes.string,
		children: PropTypes.node
	};

	getDefer = () => {
		return this.defer;
	};

	open = () => {
		this.popup.open();
		this.defer = deferred();

		return this.defer.promise;
	};

	close = () => {
		this.popup.close();
	};

	handleRejectClose = () => {
		this.defer.reject();
		this.close();
	};

	render() {
		const { className, children } = this.props;

		return (
			<MainPopup
				ref={popup => (this.popup = popup)}
				className={cn("content-popup", className)}
				footer={null}
				rejectClose={this.handleRejectClose}
				maskClosable={false}
			>
				{children}
			</MainPopup>
		);
	}
}
