import React, { Component } from "react";
import PropTypes from "prop-types";
import NotFoundLayer from "./NotFoundLayer";

export default class ErrorBoundary extends Component {
	static propTypes = {
		children: PropTypes.node
	};

	state = {
		hasError: false
	};

	componentDidCatch() {
		this.setState({
			hasError: true
		});
	}

	render() {
		const { children } = this.props;
		const { hasError } = this.state;

		if (hasError) {
			return <NotFoundLayer />;
		}

		return children;
	}
}
