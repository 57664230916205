import React from "react";
import PropTypes from "prop-types";
import { ConnectedRouter } from "connected-react-router";
import { BrowserRouter } from "react-router-dom";

export const withBrowserRouter = () => Component => {
	const withBrowserRouterHOC = ({ base, history, ...props }) => {
		return (
			<BrowserRouter>
				<ConnectedRouter history={history}>
					<Component {...props} />
				</ConnectedRouter>
			</BrowserRouter>
		);
	};

	withBrowserRouterHOC.propTypes = {
		history: PropTypes.shape({}).isRequired,
		base: PropTypes.string
	};

	return withBrowserRouterHOC;
};
