import { applyMiddleware } from "redux";

export default (modules, ...customMiddleWares) => {
	const composerFunc = [];
	const middleware = [...customMiddleWares];

	modules.forEach(module => {
		if (module.getMiddlewares && typeof module.getMiddlewares === "function") {
			middleware.push(module.getMiddlewares());
		}
	});

	return [applyMiddleware(...middleware), ...composerFunc];
};
