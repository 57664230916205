import { reducer as formReducer } from "redux-form";

function defaultHandler(state) {
	return state;
}

export const createReducer = (initialState, actionHandlers) => {
	return function reducer(state = initialState, action) {
		const handler = actionHandlers[action.type] || actionHandlers.default || defaultHandler;
		return handler(state, action.payload);
	};
};

export default modules => {
	const _reducers = {
		form: formReducer
	};

	return modules.reduce((reducers, module) => {
		if (module.getReducers && typeof module.getReducers === "function") {
			return {
				...reducers,
				...module.getReducers(createReducer)
			};
		} else {
			return reducers;
		}
	}, _reducers);
};
