import { all, call, put, debounce } from "redux-saga/effects";
import { escapeRegExp } from "common/helpers/string";
import * as controlDataContext from "common/dataProvider/control";

import * as action from "../actions/smartAction";

export function* searchUserAutoCompleteSaga({ payload }) {
	try {
		const { value } = payload;
		const query = { size: 10, q: value ? `quickSearch=='${escapeRegExp(value)}'` : undefined };
		const response = yield call(controlDataContext.getControlUser, { query });

		yield put(
			action.searchUserAutoComplete.success({
				items: response.content
			})
		);
	} catch (e) {
		yield put(action.searchUserAutoComplete.error());
	}
}

export function* searchCompanyAutoCompleteSaga({ payload }) {
	try {
		const query = { size: 10, q: payload ? `quickSearch=='${escapeRegExp(payload)}'` : undefined };
		const response = yield call(controlDataContext.getControlCompany, { query });

		yield put(
			action.searchCompanyAutoComplete.success({
				items: response.content
			})
		);
	} catch (e) {
		yield put(action.searchCompanyAutoComplete.error());
	}
}

export default function*() {
	yield all([
		debounce(400, action.searchUserAutoComplete.request, searchUserAutoCompleteSaga),
		debounce(400, action.searchCompanyAutoComplete.request, searchCompanyAutoCompleteSaga)
	]);
}
