import React from "react";
import moment from "moment";
import { isEmpty } from "../validators";

export function copyToBuffer(text, box) {
	const textArea = document.createElement("textarea");

	textArea.value = text;

	textArea.style.width = "0";
	textArea.style.height = "0";

	(box.appendChild ? box : document.body).appendChild(textArea);
	textArea.focus();
	if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
		iosSelectTextToCopyToClipboard(textArea);
	} else {
		textArea.select();
	}

	let result = false;
	try {
		result = document.execCommand("copy");
	} catch (e) {
		// ignore error
	}

	(box.removeChild ? box : document.body).removeChild(textArea);

	return result;
}

/**
 * Select text on ios devices >=10
 * @param el - node
 * http://qaru.site/questions/165883/copy-to-clipboard-using-javascript-in-ios
 */
function iosSelectTextToCopyToClipboard(el) {
	// save current contentEditable/readOnly status
	var editable = el.contentEditable;
	var readOnly = el.readOnly;

	// convert to editable with readonly to stop iOS keyboard opening
	el.contentEditable = true;
	el.readOnly = true;

	// create a selectable range
	var range = document.createRange();
	range.selectNodeContents(el);

	// select the range
	var selection = window.getSelection();
	selection.removeAllRanges();
	selection.addRange(range);
	el.setSelectionRange(0, 999999);

	// restore contentEditable/readOnly to original state
	el.contentEditable = editable;
	el.readOnly = readOnly;
}

export function escapeRegExp(string = "") {
	return ("" + string).replace(/[.'" *+?^${}()|[\]\\]/g, "\\$&");
}

/**
 * Преобразует URL search строку в object
 * @param search {string}
 * @returns {object}
 */
export const searchToObject = (search = "") => {
	const query = {};
	const params = (search[0] === "?" ? search.substr(1) : search).split("&");

	for (let i = 0; i < params.length; i++) {
		const param = params[i].split(/=(.+)/);

		if (decodeURIComponent(param[0]) === "redirect") {
			const redirectParam = search.split(/redirect=(.+)/);
			query[decodeURIComponent(param[0])] = decodeURIComponent(redirectParam[1] || "");
			continue;
		}

		query[decodeURIComponent(param[0])] = decodeURIComponent(param[1] || "");
	}

	return query;
};

/**
 * Преобразует object в URL search строку
 * @param obj {object}
 * @returns {string}
 */
export const objectToSearch = (obj = {}, encodeValue = true) => {
	let search = "";

	for (let key in obj) {
		if (obj.hasOwnProperty(key) && obj[key] !== undefined)
			search +=
				(search.length > 0 ? "&" : "") +
				`${encodeURIComponent(key)}=${encodeValue ? encodeURIComponent(obj[key]) : obj[key]}`;
	}

	return search;
};

export const getOfferDocForUserType = (userType = "kassa") => {
	return `/site/offer/${userType}`;
};

export const getString = (value = "", titleText = "") => {
	return !isEmpty(value) ? <span title={"" + titleText}>{value}</span> : <span className="f-12">нет данных</span>;
};

export const getDateFormat = (dateTime, isShowTime = false, isStrPeriod = false) => {
	const now = moment();
	const format = `DD.MM.YYYY${isShowTime ? " LTS" : ""}`;

	if (!dateTime) {
		return getString();
	}

	if (isStrPeriod) {
		return now.subtract(1, "second") < moment(dateTime)
			? moment(dateTime).format(format)
			: getString(now.to(moment(dateTime)), moment(dateTime).format(format));
	} else {
		return moment(dateTime).format(format);
	}
};
