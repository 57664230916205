import React, { Component } from "react";
import PropTypes from "prop-types";
import FormItem from "antd/lib/form/FormItem";

const FIELD_EMPTY_ERROR = "FIELD_EMPTY_ERROR";

export default WrappedComponent => {
	class withFieldValidator extends Component {
		static propTypes = {
			// redux-form props
			// https://redux-form.com/8.2.0/docs/api/field.md
			meta: PropTypes.shape({
				touched: PropTypes.bool,
				invalid: PropTypes.bool,
				error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
				warning: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),

				// for "setFocus"
				active: PropTypes.bool,
				visited: PropTypes.bool
			}),

			// wrapper props
			input: PropTypes.shape({}),
			children: PropTypes.node,
			styledError: PropTypes.func
			// t: PropTypes.func.isRequired
		};

		isDirtyNotActive = false;

		constructor(props) {
			super(props);
			this.fieldRef = React.createRef();
		}

		componentDidMount() {
			const {
				meta: { active, visited }
			} = this.props;

			if (active && visited) {
				this.setFocus();
			}
		}

		componentDidUpdate(prevProps) {
			const {
				meta: { active, visited }
			} = this.props;
			const {
				meta: { active: wasActive }
			} = prevProps;

			if (active && visited && !wasActive) {
				this.setFocus();
			}
		}

		setFocus() {
			this.fieldRef?.current?.setFocus && this.fieldRef?.current?.setFocus();
		}

		isError = () => {
			const {
				meta: { error, submitFailed, dirty, active }
			} = this.props;

			if (dirty && !active) {
				this.isDirtyNotActive = true;
			}

			return error !== undefined && (submitFailed || this.isDirtyNotActive);
		};

		isWarning = () => {
			const {
				meta: { warning, submitFailed, dirty, active }
			} = this.props;

			if (dirty && !active) {
				this.isDirtyNotActive = true;
			}

			return warning !== undefined && (submitFailed || this.isDirtyNotActive);
		};

		getValidateStatus = () => {
			const {
				meta: { touched /*, invalid*/ }
			} = this.props;
			const isError = this.isError();
			const isWarning = this.isWarning();

			//return isError && touched /* && invalid*/ ? "error" : "success";
			if (isError && touched) {
				return "error";
			} else if (isWarning && touched) {
				return "warning";
			}
		};

		getFormItemProps = () => {
			const {
				label,
				desc,
				labelCol,
				wrapperCol,
				hasFeedback,
				meta: { error, warning }
			} = this.props;

			const status = this.getValidateStatus();
			const isError = this.isError();
			const isWarning = this.isWarning();
			const showErrorText = isError && status === "error" && error !== FIELD_EMPTY_ERROR;
			const showWarningText = isWarning && status === "warning" && error !== FIELD_EMPTY_ERROR;
			const help = showErrorText ? error : showWarningText ? warning : "";
			let showHasFeedback = hasFeedback;
			showWarningText ? (showHasFeedback = true) : (showHasFeedback = hasFeedback);

			return {
				labelCol,
				wrapperCol,
				label,
				extra: !help ? desc : "",
				help,
				hasFeedback: showHasFeedback && !!status,
				validateStatus: status
			};
		};

		render() {
			const { input, children, ...props } = this.props;
			const propsFormItem = this.getFormItemProps();

			// for FormItem
			delete props.label;
			delete props.desc;
			delete props.labelCol;
			delete props.wrapperCol;
			delete props.hasFeedback;
			delete props.meta;
			delete props.styledError;
			delete props.t;
			delete props.tReady;

			return (
				<FormItem {...propsFormItem}>
					<WrappedComponent {...input} {...props} ref={this.fieldRef}>
						{children}
					</WrappedComponent>
				</FormItem>
			);
		}
	}

	return withFieldValidator;
};
