import { all, fork, takeEvery, call, put } from "redux-saga/effects";
import moment from "moment";
import { push } from "connected-react-router";
import { redirect } from "common/helpers/location";
import { SIGN_IN_PAGE } from "common/enums/app";
import { setUserCompany } from "modules/account/actions/accountAction";
import * as loginDataContext from "common/dataProvider/login";
import * as userDataContext from "common/dataProvider/user";
import * as companyDataContext from "common/dataProvider/company";

import * as action from "../actions/appAction";

/**
 * Logic initialize application
 * @returns {Generator}
 * @public
 */
export function* initApplicationSaga() {
	try {
		moment.updateLocale(moment.locale("ru"), {
			week: { dow: 1 }
		});

		// user account
		const account = yield call(loginDataContext.postCheckLogin);
		const { company, childCompany } = yield call(loadCompaniesSaga, { account });

		yield put(setUserCompany({ company }));
		yield put(
			action.setAppReady({
				isReady: true,
				account,
				company,
				childCompany
			})
		);
	} catch (e) {
		yield put(action.setAppReady({ isReady: true }));
	}
}

export function* loadCompaniesSaga({ account }) {
	let result = {
		company: [],
		childCompany: []
	};

	try {
		result = yield all({
			// user company
			company: call(companyDataContext.getCompany),
			// loading child company
			childCompany: call(companyDataContext.getCompanyChildCompany, {
				companyId: account.currentCompanyId,
				query: { size: 100 }
			})
		});
	} catch (e) {
		return {
			company: [],
			childCompany: []
		};
	}

	return result;
}

export function* logoutSaga() {
	try {
		yield call(loginDataContext.postLogout);
		yield put(action.logout.success());

		redirect(SIGN_IN_PAGE);
	} catch (e) {
		yield put(action.logout.error());
	}
}

export function* selectCompanyState({ payload }) {
	try {
		yield call(userDataContext.putUser, { currentCompanyId: payload.id });

		yield put(action.selectCompany.success({ currentCompanyId: payload.id }));
		yield put(push("/"));
	} catch (e) {
		yield put(action.selectCompany.error());
	}
}

export default function*() {
	yield all([
		fork(initApplicationSaga),

		takeEvery(action.logout.request, logoutSaga),
		takeEvery(action.selectCompany.request, selectCompanyState)
	]);
}
