export const EMAIL_REGEXP = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9-])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

/**
 * Проверка контрольного числа ИНН
 * @param INN
 * @returns {boolean}
 */
export const isCorrectInn = INN => {
	const factor1 = [2, 4, 10, 3, 5, 9, 4, 6, 8];
	const factor2 = [7, 2, 4, 10, 3, 5, 9, 4, 6, 8];
	const factor3 = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8];

	let i = 0;
	let sum = 0;
	let sum2 = 0;
	let Result = false;
	let d;

	if (INN.length === 0) {
		Result = true;
	} else if (INN.length === 10) {
		// Юр.лицо
		sum = 0;

		for (i = 0; i <= 8; i++) {
			d = INN.slice(i, i + 1);
			sum += d * factor1[i];
		}

		sum = sum % 11;
		sum = sum % 10;

		Result = INN.slice(9, 10) == sum;
	} else if (INN.length === 12) {
		// Физ.лицо или ИП
		sum = 0;

		for (i = 0; i <= 9; i++) {
			d = INN.slice(i, i + 1);
			sum += d * factor2[i];
		}

		sum = sum % 11;
		sum = sum % 10;
		sum2 = 0;

		for (i = 0; i <= 10; i++) {
			d = INN.slice(i, i + 1);
			sum2 += d * factor3[i];
		}

		sum2 = sum2 % 11;
		sum2 = sum2 % 10;

		Result = INN.slice(10, 11) == sum && INN.slice(11, 12) == sum2;
	}

	return Result;
};

/**
 * Проверка контрольного числа КПП
 * @param KPP
 * @returns {boolean}
 */
export const isCorrectKpp = KPP => {
	if (typeof KPP === "number") {
		KPP = KPP.toString();
	} else if (typeof KPP !== "string") {
		KPP = "";
	}

	if (!KPP.length || KPP.length !== 9) {
		return false;
	} else if (!/^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(KPP)) {
		return false;
	} else return true;
};
