import { USER_TYPE_DEFAULT } from "../../enums/user";

export const createUser = {
	toServer: data => ({
		userId: data.searchUser ? data.userId : null,
		// prettier-ignore
		userData: data.searchUser
			? null
			: {
				email: data.userData.email,
				firstName: data.userData.firstName,
				lastName: data.userData.lastName,
				middleName: data.userData.middleName,
				phone: data.userData.phone,
				password: data.userData.password,
				userType: data.userType || USER_TYPE_DEFAULT,
			},
		accessRights: {
			showUserCompany: !!data.accessRights?.showUserCompany,
			editUserCompany: !!data.accessRights?.editUserCompany,
			showChildrenCompany: !!data.accessRights?.showChildrenCompany,
			editChildrenCompany: !!data.accessRights?.editChildrenCompany,
			showApiTokenKkm: !!data.accessRights?.showApiTokenKkm,
			changeApiTokenKkm: !!data.accessRights?.changeApiTokenKkm,
			showCallbackKkm: !!data.accessRights?.showCallbackKkm,
			changeCallbackKkm: !!data.accessRights?.changeCallbackKkm,
			sendTransactionKkm: !!data.accessRights?.sendTransactionKkm
		}
	})
};

export const callback = {
	toServer: data => ({
		callbackUrl: data.callbackUrl,
		secretKey: data.secretKey
	})
};
