import "antd/lib/button/style";

import React, { Component } from "react";
import Button from "antd/lib/button";

export default class extends Component {
	static displayName = "Button";

	static defaultProps = {
		size: "small"
	};

	render() {
		const { ...props } = this.props;

		return <Button {...props} />;
	}
}
