// redux form
import { Field, FieldArray } from "redux-form";
import reduxForm from "./reduxForm";
import Form from "./Form";

// Main element form
import InputField from "./InputField";
import PhoneField from "./PhoneField";
import DatePickerField from "./DatePickerField";
import AmountField from "./AmountField";
import SelectField from "./SelectField";
import NumberField from "./NumberField";
import RadioField from "./RadioField";
import AutoCompleteField from "./AutoCompleteField";
import CheckboxField from "./CheckboxField";
import SwitchField from "./SwitchField";

export default reduxForm;
export {
	Form,
	Field,
	FieldArray,
	InputField,
	PhoneField,
	DatePickerField,
	AmountField,
	SelectField,
	NumberField,
	RadioField,
	AutoCompleteField,
	CheckboxField,
	SwitchField
};
