/* eslint-disable */
import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import RouteManager from "./RouteManager";
import * as routeHelpers from "./helpers";

@withRouter
export default class extends React.Component {
	static propTypes = {
		routes: PropTypes.array.isRequired,
		notFound: PropTypes.func,
		defaultLayout: PropTypes.func,
		defaultLayerLayout: PropTypes.func,
		routeWrappers: PropTypes.arrayOf(PropTypes.func)
	};

	constructor(props, context) {
		super(props, context);

		const { routes, defaultLayout, defaultLayerLayout, routeWrappers } = props;

		this.state = {
			allRoutes: routeHelpers.transformRoutes(routes, defaultLayout, defaultLayerLayout, routeWrappers)
		};
	}

	render() {
		const { notFound, errorBoundary, location, history } = this.props;

		return (
			<RouteManager
				history={history}
				location={location}
				routes={this.state.allRoutes}
				notFound={notFound}
				errorBoundary={errorBoundary}
			/>
		);
	}
}
