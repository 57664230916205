import { createSelector } from "reselect";

const cpCompanySelect = state => state.cp.companySelect;

export const isLoadingSelector = ({ form, name }) =>
	createSelector(
		cpCompanySelect,
		state => (state[form] && state[form][name] && state[form][name].isLoading) || false
	);
export const optionsSelector = ({ form, name }) =>
	createSelector(
		cpCompanySelect,
		state => (state[form] && state[form][name] && state[form][name].options) || []
	);
export const typeSelector = ({ form, name }) =>
	createSelector(
		cpCompanySelect,
		state => (state[form] && state[form][name] && state[form][name].type) || ""
	);
