export const encrypt = (email, pass) => {
	return b64EncodeUnicode(`${email}:${pass}`);
};

export const decrypt = token => {
	const values = atob(token).split(":");
	let email = null,
		password = null;
	if (values.length == 2) {
		email = values[0];
		password = values[1];
	}
	return { email, password };
};

function b64EncodeUnicode(str) {
	return btoa(
		encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
			return String.fromCharCode(parseInt(p1, 16));
		})
	);
}

export const randString = (length = 8) => {
	let result = "";
	const string = ["ABCDEFGHIJKLMNOPQRSTUVWXYZ", "abcdefghijklmnopqrstuvwxyz", "0123456789", "@#$%"];

	for (let i = 0; i < length; i++) {
		const str = string[rand(0, string.length - 1)];
		result += str.charAt(rand(0, str.length));
	}

	return result;

	function rand(min = 0, max = 4) {
		return parseInt(Math.random() * (max - min + 1) + min);
	}
};
