/* eslint-disable */
import React from "react";

export default RouteComponent => {
	class LayoutRoute extends React.Component {
		renderWithLayout = () => {
			const { component: Component, layout: Layout, ...props } = this.props;
			return <Layout {...props}>{Component && <Component />}</Layout>;
		};

		render() {
			const { ...props } = this.props;
			delete props.component;
			return <RouteComponent {...props} render={this.renderWithLayout} />;
		}
	}

	return LayoutRoute;
};
