import api from "common/api/index";
import * as mapper from "./mapper";

// PUT /site/user
export const putUser = ({ companyId }) => {
	return api
		.site()
		.user()
		.put({ currentCompanyId: companyId })
		.then(res => res.data);
};

// GET /site/company
export const getCompany = () => {
	return api
		.site()
		.company()
		.get()
		.then(res => res.data);
};

// GET /site/company/{companyId}/user
export const getCompanyIdUser = ({ companyId }) => {
	return api
		.site()
		.company(companyId)
		.user()
		.get()
		.then(res => res.data);
};

// GET /site/company/{companyId}/kkm
export const getCompanyKkm = ({ companyId, query }) => {
	return api
		.site()
		.company(companyId)
		.kkm()
		.get(query)
		.then(res => res.data);
};

// POST /site/company/{companyId}/kkm-group
export const postCompanyByIdKkmGroup = ({ companyId, query }) => {
	return api
		.site()
		.company(companyId)
		.kkmGroup()
		.post(query);
};

// GET /site/company/{companyId}/kkm-group/{kkmGroupId}
export const getCompanyKkmGroupId = ({ companyId, kkmGroupId }) => {
	return api
		.site()
		.company(companyId)
		.kkmGroup(kkmGroupId)
		.get()
		.then(res => res.data);
};

// PUT /site/company/{companyId}/kkm-group/{kkmGroupId}
export const putCompanyKkmGroupId = ({ companyId, kkmGroupId, query }) => {
	return api
		.site()
		.company(companyId)
		.kkmGroup(kkmGroupId)
		.put(query);
};

// POST /site/company/{companyId}/kkm-group/{groupId}/api-key
export const postCompanyKkmGroupIdApiKey = ({ companyId, kkmGroupId, query }) => {
	return api
		.site()
		.company(companyId)
		.kkmGroup(kkmGroupId)
		.apiKey()
		.post(query)
		.then(res => res.data);
};

// DELETE /site/company/{companyId}/kkm-group/{kkmGroupId}
export const deleteCompanyKkmGroupId = ({ companyId, kkmGroupId }) => {
	return api
		.site()
		.company(companyId)
		.kkmGroup(kkmGroupId)
		.delete();
};

// GET /site/company/{companyId}/kkm-group?page=2&size=10
export const getCompanyKkmGroup = ({ companyId, query }) => {
	return api
		.site()
		.company(companyId)
		.kkmGroup()
		.get(query)
		.then(res => res.data);
};

// GET /site/company/{companyId}/kkm/{kkmId}
export const getCompanyKkmId = ({ companyId, kkmId }) => {
	return api
		.site()
		.company(companyId)
		.kkm(kkmId)
		.get()
		.then(res => res.data);
};

// GET /company/{companyId}/kkm/{kkmId}/transaction/{externalId}
export const getCompanyKkmTransactionId = ({ companyId, kkmId, transactionId, global }) => {
	return api
		.site()
		.company(companyId)
		.kkm(kkmId)
		.transaction(transactionId)
		.get({ global })
		.then(res => res.data);
};

// GET /site/company/{companyId}/kkm/{kkmId}/transaction?q=<query_definition>
export const getCompanyKkmTransaction = ({ companyId, kkmId, query }) => {
	return api
		.site()
		.company(companyId)
		.kkm(kkmId)
		.transaction()
		.get(query)
		.then(res => res.data);
};

// GET /site/company/{companyId}/kkm/{kkmId}/correction?q=<query_definition>
export const getCompanyKkmCorrection = ({ companyId, kkmId, query }) => {
	return api
		.site()
		.company(companyId)
		.kkm(kkmId)
		.correction()
		.get(query)
		.then(res => res.data);
};

// GET /site/company/4bb835e2-6a46-4807-9798-4ef6f1c4287d/kkm/c4bafc1e-ee2c-47f5-8598-9300f770df34/correction/1605950604215
export const getCompanyKkmCorrectionDetail = ({ companyId, kkmId, requestId }) => {
	return api
		.site()
		.company(companyId)
		.kkm(kkmId)
		.correction(requestId)
		.get()
		.then(res => res.data);
};

// GET /site/company/{companyId}/group/{kkmGroupId}/transaction?q=<query_definition>
export const getCompanyGroupTransaction = ({ companyId, kkmGroupId, query }) => {
	return api
		.site()
		.company(companyId)
		.group(kkmGroupId)
		.transaction()
		.get(query)
		.then(res => res.data);
};

// GET /site/company/{companyId}/user
export const getCompanyUser = ({ companyId, query }) => {
	return api
		.site()
		.company(companyId)
		.user()
		.get(query)
		.then(res => res.data);
};

// GET /site/company/{companyId}/child-company
export const getCompanyChildCompany = ({ companyId, query }) => {
	return api
		.site()
		.company(companyId)
		.childCompany()
		.get(query)
		.then(res => res.data?.content);
};

// POST /site/company/{companyId}/kkm/{kkmId}/name
export const postCompanyByIdKkmByIdName = ({ companyId, kkmId, query }) => {
	return api
		.site()
		.company(companyId)
		.kkm(kkmId)
		.name()
		.post(query)
		.then(res => res.data);
};

// POST /site/company/{companyId}/kkm/{kkmId}/child-company
export const postCompanyByIdKkmByIdChildCompany = ({ companyId, kkmId, query }) => {
	return api
		.site()
		.company(companyId)
		.kkm(kkmId)
		.childCompany()
		.post(query)
		.then(res => res.data);
};

// POST /site/company/{companyId}/kkm/{kkmId}/timezone
export const postCompanyByIdKkmByIdTimezone = ({ companyId, kkmId, query }) => {
	return api
		.site()
		.company(companyId)
		.kkm(kkmId)
		.timezone()
		.post(query)
		.then(res => res.data);
};

// DELETE /site/user/{userId}
export const deleteUserById = ({ userId }) => {
	return api
		.site()
		.user(userId)
		.delete();
};

// PUT /site/company/{companyId}/user/{userId}/access-rights
export const putCompanyByIdUserByIdAccessRights = ({ companyId, userId, query }) => {
	return api
		.site()
		.company(companyId)
		.user(userId)
		.accessRights()
		.put(query);
};

// POST /site/company/{companyId}/user
export const postCompanyByIdUser = ({ companyId, query }) => {
	return api
		.site()
		.company(companyId)
		.user()
		.post(mapper.createUser.toServer(query));
};

// POST /site/company/{companyId}/kkm/{kkmId}/api-key
export const postCompanyByIdKkmByIdApiKey = ({ companyId, kkmId }) => {
	return api
		.site()
		.company(companyId)
		.kkm(kkmId)
		.apiKey()
		.post()
		.then(res => res.data);
};

// POST /site/company/{companyId}/kkm/{kkmId}/callback
export const postCompanyByIdKkmByIdCallback = ({ companyId, kkmId, form }) => {
	return api
		.site()
		.company(companyId)
		.kkm(kkmId)
		.callback()
		.post(mapper.callback.toServer(form))
		.then(res => res.data);
};

// GET /site/company/{companyId}/registry
export const getCompanyByIdRegistry = ({ companyId, query }) => {
	return api
		.site()
		.company(companyId)
		.registry()
		.get(query)
		.then(res => res.data);
};

// GET /site/company/{companyId}/registry/{registryId}/lines
export const getCompanyByIdRegistryByIdLines = ({ companyId, registryId, query }) => {
	return api
		.site()
		.company(companyId)
		.registry(registryId)
		.lines()
		.get(query)
		.then(res => res.data);
};

// DELETE /site/company/{companyId}/kkm/{kkmId}/group
export const deleteCompanyByIdKkmByIdGroup = ({ companyId, kkmId }) => {
	return api
		.site()
		.company(companyId)
		.kkm(kkmId)
		.group()
		.delete();
};

// DELETE /site/company/{companyId}/user/{userId}
export const deleteCompanyByIdUserById = ({ companyId, userId }) => {
	return api
		.site()
		.company(companyId)
		.user(userId)
		.delete();
};

// POST /site/company/{companyId}/link-kkm-group
export const postCompanyByIdLinkKkmGroup = ({ companyId, query }) => {
	return api
		.site()
		.company(companyId)
		.linkKkmGroup()
		.post(query);
};
