import React, { Component } from "react";
import { reduxForm as _reduxForm } from "redux-form";

export default ({ form, ...ownProps }) => WrapperComponent => {
	const FormComponent = _reduxForm({ form, ...ownProps })(WrapperComponent);

	class ReduxForm extends Component {
		render() {
			const props = this.props;
			return <FormComponent {...props} />;
		}
	}

	return ReduxForm;
};
