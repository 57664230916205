export default api => {
	const company = api.addResource("company");

	company.addResource("childCompany", "child-company");
	company.addResource("linkKkmGroup", "link-kkm-group");

	const kkmGroup = company.addResource("kkmGroup", "kkm-group");
	kkmGroup.addResource("apiKey", "api-key");

	const user = company.addResource("user");
	user.addResource("accessRights", "access-rights");

	const registry = company.addResource("registry");
	registry.addResource("lines");
	registry.addResource("import");

	const kkm = company.addResource("kkm");
	kkm.addResource("apiKey", "api-key");
	kkm.addResource("callback");
	kkm.addResource("childCompany", "child-company");
	kkm.addResource("timezone");
	kkm.addResource("name");
	kkm.addResource("transaction");
	kkm.addResource("correction");
	kkm.addResource("group");

	const group = company.addResource("group");
	group.addResource("transaction");
};
