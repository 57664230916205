// Reducers...
import appReducer from "./reducers/appReducer";
import smartReducer from "./reducers/smartReducer";

// Sagas...
import appSaga from "./sagas/appSaga";
import smartSaga from "./sagas/smartSaga";

// Exports...
export const getReducers = createReducer => ({
	app: appReducer(createReducer),
	smart: smartReducer(createReducer)
});
export const getSagas = () => [appSaga(), smartSaga()];
