import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import "antd/lib/radio/style";
import Radio from "antd/lib/radio";

import hocValidateField from "./helpers/hocValidateField";

const ARadio = hocValidateField(Radio.Group);
const RadioField = props => <Field {...props} component={ARadio} />;

RadioField.propTypes = {
	name: PropTypes.string.isRequired
};

RadioField.Radio = Radio;

export default RadioField;
