import React from "react";
import PropTypes from "prop-types";

import "antd/lib/empty/style";
import Empty from "antd/lib/empty";

const NoItems = ({ isShow, description }) =>
	isShow && (
		<div className="row">
			<div className="col-12">
				<div className="card m-0">
					<div className="card-block p-0">
						<Empty className="mt-5 mb-5" description={description} />
					</div>
				</div>
			</div>
		</div>
	);

NoItems.propTypes = {
	isShow: PropTypes.bool,
	description: PropTypes.string
};
NoItems.defaultProps = {
	description: "Нет доступных элементов для отображения"
};

export default NoItems;
