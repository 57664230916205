import { withPrivateRoute } from "./withPrivateRoute";
import { withOnlyPublicRoute } from "./withOnlyPublicRoute";

export default [
	(route, props) => {
		if ("allowAuthorized" in props && props.allowAuthorized === false) {
			route = withOnlyPublicRoute(route);
		}

		if (!props.allowAnonymous) {
			route = withPrivateRoute(route);
		}

		return route;
	}
];
