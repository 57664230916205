import axios from "axios";
import Interceptors from "../../core/http/Interceptors";
import tokenInterceptorCreator from "../../core/http/tokenInterceptor";
import Http from "../../core/http/Http";
import createApi from "../../core/http/createApi";
import resourceApp from "./resource";

export default (() => {
	const _interceptors = new Interceptors();
	const tokenInterceptor = tokenInterceptorCreator();

	_interceptors.push(tokenInterceptor);

	const _http = new Http(axios, _interceptors);
	const api = createApi(_http);

	// подключение ресурсов
	resourceApp(api);

	// Мокки доступны ТОЛЬКО в режиме разработки
	if (__DEV__) {
		// подключение мокков
		const mocker = require("./mocks").default(axios);

		mocker.axiosInstance.request = params =>
			new Promise((resolve, reject) => {
				axios(params).then(resolve, reject);
			});
		mocker.onAny().passThrough();
	}

	return api;
})();
