/* eslint-disable */
import React from "react";
import { Switch } from "react-router-dom";
import PropTypes from "prop-types";

import { isLayerPage } from "./helpers";

export default class PageManager extends React.Component {
	static propTypes = {
		routes: PropTypes.shape({
			pageRoutes: PropTypes.array.isRequired,
			layerRoutes: PropTypes.array.isRequired
		}),
		notFound: PropTypes.func,
		pageLocation: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired
	};

	shouldComponentUpdate(nextProps) {
		const { location, routes } = nextProps;
		const { returnToPage } = location.state || {};

		return !isLayerPage(routes.layerRoutes, location) && !returnToPage;
	}

	render() {
		const { routes, pageLocation, notFound: NotFound } = this.props;

		return (
			<Switch location={pageLocation}>
				{routes.pageRoutes.map(({ ExtendedRoute, routeId, ...rest }) => {
					return <ExtendedRoute key={routeId} {...rest} />;
				})}
				{/*{routes.pageRoutes.map(route =>*/}
				{/*GenerateRoute({props: route, routeId: route.routeId, routeWrappers})*/}
				{/*)}*/}
				{NotFound && <NotFound />}
			</Switch>
		);
	}
}
