import "antd/lib/date-picker/style";

import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DatePicker from "antd/lib/date-picker";
import { isEmpty } from "common/validators";

export default class extends Component {
	static displayName = "DatePicker";

	static propTypes = {
		allowClear: PropTypes.bool,
		showToday: PropTypes.bool,
		extraDate: PropTypes.bool,
		format: PropTypes.string,
		initFormat: PropTypes.string,
		placeholder: PropTypes.string,
		value: PropTypes.any,
		onChange: PropTypes.func,
		hideIcon: PropTypes.bool,
		size: PropTypes.string,
		min: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.instanceOf(moment)]),
		max: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.instanceOf(moment)])
	};

	static defaultProps = {
		allowClear: false,
		showToday: false,
		extraDate: false,
		placeholder: "",
		size: "small",
		format: "DD.MM.YYYY",
		initFormat: "YYYY-MM-DD HH:mm Z" // UTC
	};

	constructor(props) {
		super(props);
		this.dateRef = React.createRef();
	}

	state = { value: null };

	componentDidMount() {
		const { value } = this.props;
		this.setState({ value: this.parseValue(value) });
	}

	shouldComponentUpdate(nextProps) {
		const { value } = this.state;
		const _value = this.parseValue(nextProps.value);

		if (nextProps.value !== "Invalid date" && !this.isEqualValues(value, _value)) {
			this.setState({ value: _value });

			return false;
		}

		return true;
	}

	isEqualValues = (prevValue, nextValue) => {
		return (
			(prevValue && nextValue && prevValue.valueOf() === nextValue.valueOf()) ||
			// prevValue === nextValue ||
			// (prevValue && nextValue) ||
			(isEmpty(prevValue) && isEmpty(nextValue))
		);
	};

	parseValue = value => {
		const { format, initFormat } = this.props;

		return isEmpty(value) ? null : moment(value, [format, initFormat]);
	};

	handleChangeDate = value => {
		const { format, onChange } = this.props;
		const _value = moment(value, format)
			.utc()
			.format();

		value && onChange && onChange(_value);
	};

	handleBlurDate = () => {
		// add logic if need
	};

	handleExtraDays = code => () => {
		let _value = null;

		switch (code) {
			case "today":
				_value = moment();
				break;
			case "7days":
				_value = moment().add(7, "days");
				break;
			case "14days":
				_value = moment().add(14, "days");
				break;
			case "30days":
				_value = moment().add(30, "days");
				break;
		}

		// WTF ??? This is Ant.D
		const date = this.dateRef?.current;
		date && date.picker && date.picker.setState({ open: false });

		this.setState({ value: _value }, () => {
			this.handleChangeDate(_value);
		});
	};

	renderExtraFooter = () => {
		return (
			<>
				<span onClick={this.handleExtraDays("today")}>Сегодня</span>
				<span onClick={this.handleExtraDays("7days")}>7 дней</span>
				<span onClick={this.handleExtraDays("14days")}>14 дней</span>
				<span onClick={this.handleExtraDays("30days")}>30 дней</span>
			</>
		);
	};

	disabledDate = selectDate => {
		const { min, max } = this.props;

		// prettier-ignore
		return min || max ? (
			(min ? moment(min).startOf("day") > selectDate.startOf("day") : false) ||
			(max ? moment(max).startOf("day") < selectDate.startOf("day") : false)
		) : false;
	};

	render() {
		const { extraDate, hideIcon, ...props } = this.props;
		const { value } = this.state;

		if (extraDate) {
			props.renderExtraFooter = this.renderExtraFooter;
		}

		return (
			<DatePicker
				ref={this.dateRef}
				{...props}
				suffixIcon={hideIcon ? <></> : <i className="icon-date" />}
				value={value}
				disabledDate={this.disabledDate}
				onChange={this.handleChangeDate}
				onBlur={this.handleBlurDate}
			/>
		);
	}
}
