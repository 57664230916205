import React from "react";
import { connect } from "react-redux";
import { isAuthUserSelector } from "../../modules/app/selectors/appSelector";
import { MAIN_PAGE } from "../../common/enums/app";
import { redirect } from "../../common/helpers/location";

export const withOnlyPublicRoute = RouteComponent => {
	let routeHoc = ({ isAuthUser, ...props }) => {
		if (!isAuthUser) {
			return <RouteComponent {...props} />;
		} else {
			redirect(MAIN_PAGE);

			return null;
		}
	};

	return connect(state => ({ isAuthUser: isAuthUserSelector(state) }))(routeHoc);
};
