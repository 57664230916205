import { EMAIL_REGEXP, isCorrectInn, isCorrectKpp } from "./checkFormat";

export const validateEmail = (email = "") => {
	return !email.length ? true : EMAIL_REGEXP.test(email);
};

export const FIELD_EMPTY_ERROR = "FIELD_EMPTY_ERROR";

export const isEmpty = val => {
	return val === "" || val === null || val === undefined;
};

export const validateRequired = valueString => (valueString ? undefined : FIELD_EMPTY_ERROR);

// НЕЛЬЗЯ ПЕРЕДАВАТЬ "messageError", ReduxForm плохо хавает такие функции
// /**
//  * Проверка контрольного числа ИНН
//  * @param messageError
//  * @returns {function(*=): *}
//  */
// export const validateINN = (messageError = "") => {
// 	return valueString =>
// 		!valueString || isCorrectInn("" + valueString) ? undefined : messageError || "Указан неверный ИНН";
// };
//
// /**
//  * Проверка формата номера КПП
//  * @param messageError
//  * @returns {function(*=): *}
//  */
// export const validateKPP = (messageError = "") => {
// 	return valueString =>
// 		!valueString || isCorrectKpp("" + valueString) ? undefined : messageError || "Указан неверный КПП";
// };

export { isCorrectInn, isCorrectKpp };
