import React from "react";

export default () => {
	return (
		<div className="loader-bg">
			<div className="loader-track">
				<div className="loader-fill" />
			</div>
		</div>
	);
};
