import React from "react";
import cn from "classnames";

const navBadge = ({ items }) => {
	let navBadges = false;

	if (items.badge) {
		navBadges = <span className={cn("label", "pcoded-badge", items.badge.type)}>{items.badge.title}</span>;
	}

	return navBadges;
};

export default navBadge;
