import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import AutoComplete from "components/ui/AutoComplete";
import hocValidateField from "./helpers/hocValidateField";

const AAutoComplete = hocValidateField(AutoComplete);
const AAutoCompleteField = props => <Field {...props} component={AAutoComplete} />;

AAutoCompleteField.propTypes = {
	name: PropTypes.string.isRequired
};

export default AAutoCompleteField;
