import React, { Component } from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import cn from "classnames";
import { isEmpty } from "common/validators";

/**
 * https://github.com/s-yadav/react-number-format
 */
export default class AmountInput extends Component {
	static propTypes = {
		thousandSeparator: PropTypes.string,
		className: PropTypes.string,
		onChange: PropTypes.func,
		onBlur: PropTypes.func,
		min: PropTypes.number,
		max: PropTypes.number,
		suffix: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
	};

	static defaultProps = {
		size: "small",
		thousandSeparator: ",",
		decimalScale: 2,
		allowNegative: false,
		placeholder: "0.00",
		fixedDecimalScale: true
	};

	setFocus = () => {
		this.input?.focus();
	};

	parseValue = value => {
		const { thousandSeparator } = this.props;
		return thousandSeparator ? value.replace(new RegExp(thousandSeparator, "g"), "") : value;
	};

	handleChange = e => {
		const { onChange } = this.props;
		const { value } = e.target;

		onChange && onChange(this.parseValue(value));
	};

	handleBlur = e => {
		const { onBlur } = this.props;
		const { value } = e.target;

		onBlur && onBlur(this.parseValue(value));
	};

	_isAllowed = ({ min, max }) => {
		if (isEmpty(min) && isEmpty(max)) return undefined;
		return ({ floatValue }) => {
			if (isEmpty(floatValue)) return true;

			if (!isEmpty(min) && typeof min === "number" && floatValue < min) return false;

			if (!isEmpty(max) && typeof max === "number" && floatValue > max) return false;

			return true;
		};
	};

	render() {
		const { className, min, max, suffix, ...props } = this.props;

		let isAllowed = this._isAllowed({ min, max });

		return (
			<div className="ant-input-affix-wrapper">
				<NumberFormat
					{...props}
					isAllowed={isAllowed}
					className={cn("ant-input", className)}
					onChange={this.handleChange}
					onBlur={this.handleBlur}
				/>
				{suffix && <div className="ant-input-suffix">{suffix}</div>}
			</div>
		);
	}
}
