import React, { Component } from "react";
import PropTypes from "prop-types";
import Input from "../Input";

export default class SearchListComponent extends Component {
	static propTypes = {
		placeholder: PropTypes.string,
		isLoading: PropTypes.bool,
		value: PropTypes.string,
		onChange: PropTypes.func
	};

	static defaultProps = {
		placeholder: "Начните вводить для поиска"
	};

	handleSearch = event => {
		const value = event.target.value;
		const { onChange } = this.props;

		onChange(value);
	};

	render() {
		const { placeholder, value, isLoading } = this.props;
		return (
			<Input
				prefix={
					isLoading ? <i className="spinner-grow spinner-grow-sm" /> : <i className="feather icon-search" />
				}
				placeholder={placeholder}
				value={value}
				onChange={this.handleSearch}
				autoFocus
			/>
		);
	}
}
