import React, { Component } from "react";
import PropTypes from "prop-types";
import deferred from "common/helpers/deferred";
import Button from "../Button";
import MainPopup from "./MainPopup";

export default class Confirm extends Component {
	static propTypes = {
		okText: PropTypes.string,
		cancelText: PropTypes.string,

		title: PropTypes.node,
		message: PropTypes.node
	};

	static defaultProps = {
		okText: "Подтвердить",
		cancelText: "Отменить"
	};

	open = () => {
		this.popup.open();
		this.defer = deferred();

		return this.defer.promise;
	};

	close = () => {
		this.popup.close();
	};

	handleResolveClose = () => {
		this.defer.resolve();
		this.popup.close();
	};

	handleRejectClose = () => {
		this.defer.reject();
		this.popup.close();
	};

	render() {
		const { title, message, okText, cancelText, ...props } = this.props;

		return (
			<MainPopup
				ref={popup => (this.popup = popup)}
				{...props}
				resolveClose={this.handleResolveClose}
				rejectClose={this.handleRejectClose}
			>
				{title && <div className="f-22 m-b-20">{title}</div>}
				{message && <div className="m-b-35">{message}</div>}

				<div>
					<Button htmlType="button" type="primary" className="m-r-15" onClick={this.handleResolveClose}>
						{okText}
					</Button>
					<Button htmlType="button" onClick={this.handleRejectClose}>
						{cancelText}
					</Button>
				</div>
			</MainPopup>
		);
	}
}
