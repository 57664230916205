import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import Phone from "components/ui/Phone";
import hocValidateField from "./helpers/hocValidateField";

const validatePhoneLen = value => {
	if (value && ("" + value).replace(new RegExp(/[-]/, "g"), "").length < 10) {
		return "укажите 10 цифр номера телефона";
	}

	return undefined;
};

const APhone = hocValidateField(Phone);
const PhoneField = props => (
	<Field {...props} component={APhone} validate={[...(props.validate || []), validatePhoneLen]} />
);

PhoneField.propTypes = {
	name: PropTypes.string.isRequired,
	textArea: PropTypes.bool,
	password: PropTypes.bool,
	validate: PropTypes.array
};

export default PhoneField;
