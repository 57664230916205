import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Loader } from "components/ui";

import UIRouter from "../core/components/UIRouter";
import { isAppReadySelector } from "../modules/app/selectors/appSelector";

import routeWrappers from "./layers/routeWrappers";
import DefaultLayout from "./layers/DefaultLayout";
import NotFoundLayer from "./layers/NotFoundLayer";
import ErrorBoundary from "./layers/ErrorBoundary";

@withRouter
@connect(state => ({
	isAppReady: isAppReadySelector(state)
}))
export default class AppComponent extends PureComponent {
	static propTypes = {
		isAppReady: PropTypes.bool,
		routes: PropTypes.arrayOf(
			PropTypes.shape({
				path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
			})
		).isRequired,
		children: PropTypes.node
	};

	render() {
		const { isAppReady, routes, children } = this.props;

		if (!isAppReady) {
			return <Loader />;
		}

		return (
			<Fragment>
				<UIRouter
					routes={routes}
					routeWrappers={routeWrappers}
					defaultLayout={DefaultLayout}
					notFound={NotFoundLayer}
					errorBoundary={ErrorBoundary}
				/>

				{children}
			</Fragment>
		);
	}
}
