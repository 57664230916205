import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import Header from "../ui/Header";
import Navigation from "../ui/Navigation";

@withRouter
export default class DefaultLayout extends Component {
	static propTypes = {
		children: PropTypes.node
	};

	render() {
		const { children } = this.props;

		return (
			<Fragment>
				<Navigation />
				<Header />

				<div className="pcoded-main-container">
					<div className="pcoded-wrapper">
						<div className="pcoded-content">
							<div className="pcoded-inner-content">{children}</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}
