import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Loader from "./Loader";

const WrapperLayer = ({ title, titleRight, breadcrumb, isLoading, children }) => (
	<Fragment>
		{title && (
			<div className="page-header">
				<div className="page-block">
					<div className="row align-items-center">
						<div className="col-md-12 mb-3">
							<div className="page-header-title">
								{!isLoading && titleRight && <div className="float-right">{titleRight}</div>}
								<h5 className="m-b-10">{title}</h5>
							</div>
							{breadcrumb.length > 0 && (
								<ul className="breadcrumb mb-0">
									<li className="breadcrumb-item">
										<Link to="/">
											<i className="feather icon-home" />
										</Link>
									</li>
									{breadcrumb.map(({ to, label, ...breadcrumbProps }) => (
										<li className="breadcrumb-item" key={to + label}>
											<Link to={to} {...breadcrumbProps}>
												{label}
											</Link>
										</li>
									))}
								</ul>
							)}
						</div>
					</div>
				</div>
			</div>
		)}

		{isLoading ? (
			<Loader />
		) : (
			<div className="main-body">
				<div className="page-wrapper">{children}</div>
			</div>
		)}
	</Fragment>
);

WrapperLayer.propTypes = {
	title: PropTypes.string,
	titleRight: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	breadcrumb: PropTypes.arrayOf(
		PropTypes.shape({
			to: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired
		})
	),
	isLoading: PropTypes.bool,
	children: PropTypes.node
};
WrapperLayer.defaultProps = {
	breadcrumb: []
};

export default WrapperLayer;
