export const STATUS = {
	QUEUED: "QUEUED",
	PENDING: "PENDING",
	COMPLETED: "COMPLETED",
	FAILED: "FAILED"
};
export const STATUS_LABEL = {
	[STATUS.QUEUED]: "В очереди",
	[STATUS.PENDING]: "В обработке",
	[STATUS.COMPLETED]: "Выполнено",
	[STATUS.FAILED]: "Ошибка"
};

export const STATUS_HISTORY_LABEL = {
	[STATUS.QUEUED]: "Добавлен в очередь",
	[STATUS.PENDING]: "Начата обработка",
	[STATUS.COMPLETED]: "Успешно выполнен",
	[STATUS.FAILED]: "Произошла ошибка"
};

export const METHOD = {
	STATUS: "status",
	INCOME: "income",
	INCOME_RETURN: "income_return",
	OUTCOME: "outcome",
	OUTCOME_RETURN: "outcome_return",
	CORRECTION: "correction"
};
export const METHOD_INFO_LABEL = {
	[METHOD.STATUS]: "Статус транзакций",
	[METHOD.INCOME]: "Ручное выполнение: Чек прихода",
	[METHOD.INCOME_RETURN]: "Ручное выполнение: Чек возврата прихода",
	[METHOD.OUTCOME]: "Ручное выполнение: Чек расхода",
	[METHOD.OUTCOME_RETURN]: "Ручное выполнение: Чек возврата расхода",
	[METHOD.CORRECTION]: "Ручное выполнение: Чек коррекции"
};

export const TYPE = {
	SALE: "SALE",
	SALE_RETURN: "SALE_RETURN",
	BUY: "BUY",
	BUY_RETURN: "BUY_RETURN"
};
export const TYPE_LABEL = {
	[TYPE.SALE]: "Приход",
	[TYPE.SALE_RETURN]: "Возврат прихода",
	[TYPE.BUY]: "Расход",
	[TYPE.BUY_RETURN]: "Возврат расхода"
};

export const PAYMENT_ITEM = [
	{ value: "1", label: "Товар" },
	{ value: "2", label: "Подакцизный товар" },
	{ value: "3", label: "Работа" },
	{ value: "4", label: "Услуга" },
	{ value: "5", label: "Ставка азартной игры" },
	{ value: "6", label: "Выигрыш азартной игры" },
	{ value: "7", label: "Лотерейный билет" },
	{ value: "8", label: "Выигрыш лотереи" },
	{ value: "9", label: "Предоставление РИД" },
	{ value: "10", label: "Платеж" },
	{ value: "11", label: "Агентское вознаграждение" },
	{ value: "12", label: "Выплата" },
	{ value: "13", label: "Иной предмет расчета" },
	{ value: "14", label: "Имущественное право" },
	{ value: "15", label: "Внереализационный доход" },
	{ value: "16", label: "Страховые взносы" },
	{ value: "17", label: "Торговый сбор" },
	{ value: "18", label: "Курортный сбор" },
	{ value: "19", label: "Залог" },
	{ value: "20", label: "Расход" },
	{ value: "21", label: "Взносы на ОПС ИП" },
	{ value: "22", label: "Взносы на ОПС" },
	{ value: "23", label: "Взносы на ОМС ИП" },
	{ value: "24", label: "Взносы на ОМС" },
	{ value: "25", label: "Взносы на ОСС" },
	{ value: "26", label: "Платеж казино" }
];

export const PAYMENT_TYPE = [
	{ value: "1", label: "Предоплата 100%" },
	{ value: "2", label: "Частичная предоплата" },
	{ value: "3", label: "Аванс" },
	{ value: "4", label: "Полный расчет" },
	{ value: "5", label: "Частичный расчет и кредит" },
	{ value: "6", label: "Передача в кредит" },
	{ value: "7", label: "Оплата кредита" }
];

export const TAX = [
	{ value: "1", label: "НДС 20%" },
	{ value: "2", label: "НДС 10%" },
	{ value: "3", label: "НДС 0%" },
	{ value: "4", label: "БЕЗ НДС" },
	{ value: "5", label: "НДС 20/120" },
	{ value: "6", label: "НДС 10/110" }
];

export const SEND_CHECK = {
	NONE: "None",
	EMAIL: "Email",
	PHONE: "Phone"
};
