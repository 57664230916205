import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/smartAction";

const initialState = {
	userAutoComplete: {
		isLoading: false,
		isNoItems: false,

		value: "",
		valueData: {},
		items: []
	},
	companyAutoComplete: {
		isLoading: false,
		isNoItems: false,

		value: "",
		valueData: {},
		items: []
	},
	companySelect: {
		id: {
			isLoading: false,
			isNoItems: false,

			items: []
		}
	}
};
const actionHandlers = {
	// User AutoComplete
	[action.searchUserAutoComplete.request]: createMutateReducer((draft, { value }) => {
		draft.userAutoComplete.isLoading = true;
		draft.userAutoComplete.value = value;
	}),
	[action.searchUserAutoComplete.success]: createMutateReducer((draft, { items }) => {
		draft.userAutoComplete.isLoading = false;
		draft.userAutoComplete.isNoItems = !items.length;
		draft.userAutoComplete.items = items;
	}),
	[action.searchUserAutoComplete.error]: createMutateReducer(draft => {
		draft.userAutoComplete.isLoading = false;
		draft.userAutoComplete.isNoItems = true;
		draft.userAutoComplete.items = [];
	}),

	// Company AutoComplete
	[action.searchCompanyAutoComplete.request]: createMutateReducer((draft, value) => {
		draft.companyAutoComplete.isLoading = true;
		draft.companyAutoComplete.value = value;
	}),
	[action.searchCompanyAutoComplete.success]: createMutateReducer((draft, { items }) => {
		draft.companyAutoComplete.isLoading = false;
		draft.companyAutoComplete.isNoItems = !items.length;
		draft.companyAutoComplete.items = items;
	}),
	[action.searchCompanyAutoComplete.error]: createMutateReducer(draft => {
		draft.companyAutoComplete.isLoading = false;
		draft.companyAutoComplete.isNoItems = true;
		draft.companyAutoComplete.items = [];
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
