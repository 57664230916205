export const controlUser = {
	toClient: data => ({
		...data,
		content: data.content.map(controlUser._toUser)
	}),

	_toUser: data => data
};

export const user = {
	toClient: data => ({
		...data,
		name: data?.lastName + " " + data?.firstName
	})
};

export const updateCompany = {
	toServer: data => ({
		companyType: data.companyType,

		name: data.name,
		parentId: data.parentId,
		address: data.address,

		inn: data.inn,
		ogrn: data.ogrn,
		kpp: data.kpp,

		managerName: data.managerName,
		managerPost: data.managerPost,
		managerPhone: data.managerPhone,

		phone: data.phone,
		email: data.email,
		website: data.website
	})
};

export const createCompany = {
	toServer: data => ({
		companyData: {
			companyType: data.companyType,

			name: data.name,
			parentId: data.parentId,
			address: data.address,

			inn: data.inn,
			ogrn: data.ogrn,
			kpp: data.kpp,

			managerName: data.managerName,
			managerPost: data.managerPost,
			managerPhone: data.managerPhone,

			phone: data.phone,
			email: data.email,
			website: data.website
		},

		userExists: !!data.searchUser,
		existingUserEmail: data.searchUser ? data.existingUserEmail : undefined,

		// prettier-ignore
		newUserData: !data.searchUser
			? {
				email: data.user.email,
				firstName: data.user.firstName,
				lastName: data.user.lastName,
				middleName: data.user.middleName,
				phone: data.user.phone,
				password: data.user.password,
				userType: data.user.userType
			}
			: undefined
	})
};

export const createKkm = {
	toServer: data => ({
		companyId: data.companyId,
		childCompanyId: data.childCompanyId,
		host: data.host,
		kkmPassword: data.kkmPassword,
		marking: data.marking,
		name: data.name,
		port: data.port
	})
};
