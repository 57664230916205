import "antd/lib/notification/style";
import notification from "antd/lib/notification";

notification.config({
	placement: "topRight",
	duration: 0
});

export default {
	error: (title, message = "", options = {}) => {
		notification.error({
			message: title,
			description: message,
			...options
		});
	},
	success: (title, message = "", options = {}) => {
		notification.success({
			message: title,
			description: message,
			...options
		});
	},
	destroy: () => {
		notification.destroy();
	},

	httpError: (title = "", message = "", options = {}) => {
		notification.error({
			message: title || "Произошла ошибка",
			description: message || "Попробуйте повторить позднее или обратитесь в техническую поддержку",
			...options
		});
	}
};
