import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import Switch from "components/ui/Switch";
import hocValidateField from "./helpers/hocValidateField";

const ASwitch = hocValidateField(Switch);
const SwitchField = props => <Field {...props} component={ASwitch} />;

SwitchField.propTypes = {
	name: PropTypes.string.isRequired
};

export default SwitchField;
