import api from "common/api";
import * as mapper from "./mapper";

// GET /site/control/user
export const getControlUser = ({ query }) => {
	return api
		.site()
		.control()
		.user()
		.get(query)
		.then(res => mapper.controlUser.toClient(res.data));
};

// GET /site/control/user/{userId}
export const getControlUserById = ({ userId }) => {
	return api
		.site()
		.control()
		.user(userId)
		.get()
		.then(res => mapper.user.toClient(res.data));
};

// POST /site/control/user
export const postControlUser = ({ query }) => {
	return api
		.site()
		.control()
		.user()
		.post(query);
};

// PUT /site/control/user
export const putControlUserById = ({ userId, query }) => {
	return api
		.site()
		.control()
		.user(userId)
		.put(query);
};

// DELETE /site/control/user/{userId}
export const deleteControlUserById = ({ userId }) => {
	return api
		.site()
		.control()
		.user(userId)
		.delete();
};

// GET /site/control/company
export const getControlCompany = ({ query }) => {
	return api
		.site()
		.control()
		.company()
		.get(query)
		.then(res => res.data);
};

// GET /site/control/company/{companyId}/child-companies
export const getControlCompanyChildCompany = ({ companyId, query }) => {
	return api
		.site()
		.control()
		.company(companyId)
		.childCompanies()
		.get(query)
		.then(res => res.data);
};

// GET /site/control/company/{companyId}
export const getControlCompanyById = ({ companyId, query }) => {
	return api
		.site()
		.control()
		.company(companyId)
		.get(query)
		.then(res => res.data);
};

// GET /site/control/company/{companyId}/users
export const getControlCompanyByIdUsers = ({ companyId, query }) => {
	return api
		.site()
		.control()
		.company(companyId)
		.users()
		.get(query)
		.then(res => res.data);
};

// GET /site/control/company/{companyId}/kkm
export const getControlCompanyByIdKkm = ({ companyId, query }) => {
	return api
		.site()
		.control()
		.company(companyId)
		.kkm()
		.get(query)
		.then(res => res.data);
};

// PUT /site/control/company/{companyId}/owner
export const putControlCompanyByIdOwner = ({ companyId, query }) => {
	return api
		.site()
		.control()
		.company(companyId)
		.owner()
		.put(query);
};

// DELETE /site/control/company/{companyId}
export const deleteControlCompanyById = ({ companyId }) => {
	return api
		.site()
		.control()
		.company(companyId)
		.delete();
};

// PUT /site/control/company/{companyId}
export const putControlCompanyById = ({ companyId, query }) => {
	return api
		.site()
		.control()
		.company(companyId)
		.put(mapper.updateCompany.toServer(query));
};

// POST /site/company { query }
export const postControlCompany = ({ query }) => {
	return api
		.site()
		.control()
		.company()
		.post(mapper.createCompany.toServer(query));
};

// GET /site/control/kkm { state }
export const getControlKkm = ({ query }) => {
	return api
		.site()
		.control()
		.kkm()
		.get(query)
		.then(res => res.data);
};

// GET /site/control/kkm/{kkmId}
export const getControlKkmById = ({ kkmId }) => {
	return api
		.site()
		.control()
		.kkm(kkmId)
		.get()
		.then(res => res.data);
};

// POST /site/control/kkm
export const postControlKkm = ({ query }) => {
	return api
		.site()
		.control()
		.kkm()
		.post(mapper.createKkm.toServer(query));
};

// PUT /site/control/kkm/{kkmId}
export const putControlKkmById = ({ kkmId, query }) => {
	return api
		.site()
		.control()
		.kkm(kkmId)
		.put(mapper.createKkm.toServer(query));
};

// DELETE /site/control/kkm/{kkmId}
export const deleteControlKkmById = ({ kkmId }) => {
	return api
		.site()
		.control()
		.kkm(kkmId)
		.delete();
};

// GET /site/control/kkm/{kkmId}/transaction
export const deleteControlKkmByIdTransaction = ({ kkmId, query }) => {
	return api
		.site()
		.control()
		.kkm(kkmId)
		.transaction()
		.get(query)
		.then(res => res.data);
};

// POST /site/control/company-user-link
export const postControlCompanyUserLink = ({ query }) => {
	return api
		.site()
		.control()
		.companyUserLink()
		.post(query);
};

// POST /site/control/company-user-unlink
export const postControlCompanyUserUnlink = ({ query }) => {
	return api
		.site()
		.control()
		.companyUserUnlink()
		.post(query);
};

// POST /site/control/user/{userId}/password
export const postControlUserByIdPassword = ({ userId, query }) => {
	return api
		.site()
		.control()
		.user(userId)
		.password()
		.post(query);
};

// GET /site/control/user/{userId}/company
export const getControlUserByIdCompany = ({ userId }) => {
	return api
		.site()
		.control()
		.user(userId)
		.company()
		.get()
		.then(res => res.data);
};

// POST /site/control/kkm/{kkmId}/mode
export const postControlKkmByIdMode = ({ kkmId, query }) => {
	return api
		.site()
		.control()
		.kkm(kkmId)
		.mode()
		.post(query);
};

// GET /site/control/registry
export const getControlRegistry = ({ query }) => {
	return api
		.site()
		.control()
		.registry()
		.get(query)
		.then(res => res.data);
};

// GET /site/control/registry/{registryId}/lines
export const getControlRegistryByIdLines = ({ registryId, query }) => {
	return api
		.site()
		.control()
		.registry(registryId)
		.lines()
		.get(query)
		.then(res => res.data);
};

// GET /site/control/offer
export const getControlOffer = () => {
	return api
		.site()
		.control()
		.offer()
		.get()
		.then(res => res.data);
};

// GET /site/control/offer/{userType}
export const getControlOfferUserType = ({ userType }) => {
	return api
		.site()
		.control()
		.offer(userType)
		.get()
		.then(res => res.data);
};

// POST /site/control/offer/{userType}
export const postControlOfferUserType = ({ userType, file }) => {
	return api
		.site()
		.control()
		.offer(userType)
		.post(file);
};

// DELETE /site/control/offer/{userType}
export const deleteControlOfferUserType = ({ userType }) => {
	return api
		.site()
		.control()
		.offer(userType)
		.delete()
		.then(res => res.data);
};

// GET /site/control/user/type
export const getControlUserType = () => {
	return api
		.site()
		.control()
		.user()
		.type()
		.get()
		.then(res => res.data);
};

// POST /site/control/user/type/{userType}
export const postControlUserType = ({ form }) => {
	return api
		.site()
		.control()
		.user()
		.type(form.id)
		.post(form);
};

// DELETE /site/control/user/type/{userType}
export const deleteControlUserType = ({ id }) => {
	return api
		.site()
		.control()
		.user()
		.type(id)
		.delete();
};
