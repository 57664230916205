export const ROLES = {
	USER: "ROLE_USER",
	ADMIN: "ROLE_ADMIN",
	PREVIOUS_ADMINISTRATOR: "ROLE_PREVIOUS_ADMINISTRATOR"
};

export const USER_TYPE_DEFAULT = "kassa";

export const USER_TYPE_LIST = [
	{ value: "api", label: "api.arendakass.ru" },
	{ value: "kassa", label: "kassa.arendakass.ru" },
	{ value: "54fz", label: "54fz.arendakass.ru" },
	{ value: "rnkb", label: "rnkb.arendakass.ru" }
];
