import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "antd/lib/select/style";
import Select from "antd/lib/select";

const Option = Select.Option;
const filterOption = (inputValue, option) =>
	("" + option.key).toLowerCase().indexOf(("" + inputValue).toLowerCase()) >= 0;

export default class extends PureComponent {
	static displayName = "Select";

	static propTypes = {
		options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])),
		optionProps: PropTypes.shape({}),
		valueKey: PropTypes.string,
		labelKey: PropTypes.string,
		descKey: PropTypes.string,
		size: PropTypes.string,
		onChange: PropTypes.func
	};

	static defaultProps = {
		options: [],
		optionProps: {},
		valueKey: "value",
		labelKey: "label",
		size: "small",
		filterOption
	};

	handleChange = value => {
		const { onChange } = this.props;
		onChange && onChange(value === undefined ? "" : value);
	};

	render() {
		const { options, valueKey, labelKey, optionProps, descKey, ...props } = this.props;

		return (
			<Select {...props} value={props.value ? props.value : undefined} onChange={this.handleChange}>
				{options.map((option, index) => (
					<Option key={option[labelKey]} value={option[valueKey] || index} {...optionProps}>
						{option[labelKey] || option}
						{descKey && option[descKey] && <div className="select__desc">{option[descKey]}</div>}
					</Option>
				))}
			</Select>
		);
	}
}
