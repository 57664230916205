import React, { Component } from "react";
import PropTypes from "prop-types";
import { Waypoint } from "react-waypoint";

import Loader from "../Loader";
import HeaderListComponent from "./HeaderListComponent";
import SearchListComponent from "./SearchListComponent";

export default class index extends Component {
	static propTypes = {
		isShow: PropTypes.bool.isRequired,
		isLoading: PropTypes.bool,
		sort: PropTypes.shape({
			field: PropTypes.string,
			order: PropTypes.oneOf(["asc", "desc"])
		}),
		header: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.string.isRequired,
				name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
				className: PropTypes.string
			})
		).isRequired,
		search: PropTypes.shape({
			placeholder: PropTypes.string,
			value: PropTypes.string,
			onChange: PropTypes.func
		}),
		onScroll: PropTypes.func,
		onSort: PropTypes.func,
		children: PropTypes.node
	};

	static defaultProps = {
		search: null
	};

	render() {
		const { isShow, isLoading, sort, header, search, onScroll, onSort, children } = this.props;

		if (!isShow) return null;

		return (
			<div className="card">
				<div className="card-block p-0">
					<div className="table-responsive">
						{isLoading && <Loader />}

						<table className="table table-hover">
							<thead>
								<HeaderListComponent sort={sort} header={header} onSort={onSort} />
							</thead>
							<tbody>
								{search && (
									<tr className="search">
										<td colSpan={header.length}>
											<SearchListComponent {...search} />
										</td>
									</tr>
								)}
								{search?.isNoItems && (
									<tr className="search">
										<td colSpan={header.length}>
											<div className="text-center">По вашему запросу ничего не найдено</div>
										</td>
									</tr>
								)}

								{children}
							</tbody>
						</table>

						<Waypoint onEnter={onScroll} scrollableAncestor={window} />
					</div>
				</div>
			</div>
		);
	}
}
