import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import deferred from "common/helpers/deferred";
import { Popup, FormItem, Select, DatePicker, Button } from "components/ui";

export default class DownloadReportPopup extends Component {
	static propTypes = {
		fieldDefault: PropTypes.string,
		fieldOptions: PropTypes.array
	};

	constructor(props) {
		super(props);

		this.state = {
			field: props.fieldDefault,
			start: moment().startOf("month"),
			end: moment().endOf("month")
		};
	}

	get isDisabled() {
		const { start, end } = this.state;
		return !(start && end);
	}

	open = () => {
		this.popup.open();
		this.defer = deferred();

		return this.defer.promise;
	};

	close = () => {
		this.popup.close();
	};

	handleFilter = ({ field, start, end }) => {
		const { field: _field, start: _start, end: _end } = this.state;
		this.setState({
			field: field || _field,
			start: start || _start,
			end: end || _end
		});
	};

	handleResolveClose = () => {
		this.defer.resolve(this.state);
		this.popup.close();
	};

	handleRejectClose = () => {
		this.defer.reject();
		this.popup.close();
	};

	render() {
		const { fieldOptions } = this.props;
		const { field, start, end } = this.state;

		return (
			<Popup.MainPopup
				ref={popup => (this.popup = popup)}
				resolveClose={this.handleResolveClose}
				rejectClose={this.handleRejectClose}
			>
				<div className="f-22 m-b-20">Выгрузить отчёт</div>
				<div className="row">
					<div className="col-12">
						<FormItem label="Сортировать по:">
							<Select
								value={field}
								options={fieldOptions}
								onChange={field => this.handleFilter({ field })}
							/>
						</FormItem>
					</div>
					<div className="col-6">
						<FormItem label="Дата от">
							<DatePicker value={start} max={end} onChange={start => this.handleFilter({ start })} />
						</FormItem>
					</div>
					<div className="col-6">
						<FormItem label="Дата до">
							<DatePicker value={end} min={start} onChange={end => this.handleFilter({ end })} />
						</FormItem>
					</div>
				</div>
				<div className="m-t-35">
					<Button
						htmlType="button"
						type="primary"
						className="m-r-15"
						onClick={this.handleResolveClose}
						disabled={this.isDisabled}
					>
						Сформировать
					</Button>
					<Button htmlType="button" onClick={this.handleRejectClose}>
						Отменить
					</Button>
				</div>
			</Popup.MainPopup>
		);
	}
}
