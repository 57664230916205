import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

export default class HeaderListComponent extends Component {
	static propTypes = {
		sort: PropTypes.shape({
			field: PropTypes.string,
			order: PropTypes.oneOf(["asc", "desc"])
		}),
		header: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.string.isRequired,
				name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
				className: PropTypes.string
			})
		),
		onSort: PropTypes.func
	};

	handleClickSort = (field, order) => () => {
		const { onSort, sort } = this.props;

		onSort &&
			onSort({
				field,
				order: sort.field === field && order === "asc" ? "desc" : "asc"
			});
	};

	render() {
		const { sort: st, onSort, header } = this.props;
		const isSorting = st && onSort;

		return (
			<tr>
				{(header || []).map(({ key, name, sort, ...props }) => (
					<th key={key} title={typeof name === "string" ? name : undefined} {...props}>
						{sort && isSorting ? (
							<span className="cursor-pointer action" onClick={this.handleClickSort(key, st.order)}>
								<i
									className={cn(
										"feather",
										{ "icon-arrow-up": st.field === key && st.order === "desc" },
										{ "icon-arrow-down": st.field === key && st.order === "asc" }
									)}
								/>
								{name}
							</span>
						) : (
							name
						)}
					</th>
				))}
			</tr>
		);
	}
}
