import React, { Component, Fragment } from "react";

export default class extends Component {
	static propTypes = {};

	render() {
		return (
			<Fragment>
				<header className="header _body">
					<a href="/" className="header__logo" />
				</header>

				<main>
					<div className="container">
						<section>
							<div className="section__header" />
							<div className="section__inner _clean">404 Not Found</div>
						</section>
					</div>
				</main>
			</Fragment>
		);
	}
}
