import { createSelector } from "reselect";

const accountSelector = state => state.account;

export const companyIdSelector = createSelector(
	accountSelector,
	state => state.currentCompanyId
);
export const userInfoSelector = createSelector(
	accountSelector,
	state => state.userInfo
);
export const companySelector = createSelector(
	accountSelector,
	state => state.company
);
export const childCompanySelector = createSelector(
	accountSelector,
	state => state.childCompany
);
export const selectedCompanySelector = createSelector(
	[companyIdSelector, companySelector],
	(companyId, company) => company.filter(e => e.id === companyId)[0] || {}
);

export const profileSelector = createSelector(
	accountSelector,
	state => state
);
