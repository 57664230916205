import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { userInfoSelector } from "modules/account/selectors/accountSelector";
import { getOfferDocForUserType } from "common/helpers/string";
import navigation from "common/enums/navigation";
import NavLogotype from "./NavLogotype";
import NavContent from "./NavContent";

@connect(state => ({
	userInfo: userInfoSelector(state)
}))
export default class extends Component {
	static propTypes = {
		userInfo: PropTypes.shape({
			userType: PropTypes.string
		})
	};

	render() {
		const { userInfo } = this.props;
		const navigationProps = {
			offerAgreementLink: getOfferDocForUserType(userInfo.userType)
		};

		return (
			<nav className="pcoded-navbar">
				<div className="navbar-wrapper">
					<NavLogotype />
					<NavContent navigation={navigation(navigationProps)} />
				</div>
			</nav>
		);
	}
}
