import React, { Component } from "react";
import PropTypes from "prop-types";
import accounting from "accounting";
import cn from "classnames";

import { isEmpty } from "../../common/validators";

export default class extends Component {
	static displayName = "AmountFormat";

	static propTypes = {
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		precision: PropTypes.number,
		def: PropTypes.string,
		className: PropTypes.string,
		whiteSpace: PropTypes.string,
		formatter: PropTypes.func,
		camelCase: PropTypes.bool,
		currency: PropTypes.string
	};

	static defaultProps = {
		currency: "RUB",
		precision: 2,
		def: "",
		className: ""
	};

	getValue = value => {
		return parseFloat(value.replace ? value.replace(/[^0-9.,]+/g, "").replace(" ", ".") : value);
	};

	getCurrency = () => {
		const { currency } = this.props;
		return currency && <span className="currency__sign">&nbsp;&#8381;</span>;
	};

	getCamelCase = value => {
		const [one, two] = value.split(".");
		return (
			<>
				{one}
				<span className="decimal">.{two}</span>
			</>
		);
	};

	getFormatter = value => {
		const { precision, camelCase, formatter } = this.props;
		const formatValue = accounting.formatNumber(value, precision, ",", ".");

		if (camelCase && !formatter) {
			return this.getCamelCase(formatValue);
		}
		if (formatter) {
			return formatter(value, formatValue);
		}

		return formatValue;
	};

	render() {
		const { value, def, precision, className, formatter, ...props } = this.props;
		const _className = cn("currency", className);

		if (isEmpty(value)) {
			return (
				<span className={_className} {...props}>
					{def}
				</span>
			);
		}

		const val = this.getValue(value);
		const formatValue = this.getFormatter(val);

		delete props.camelCase;

		return (
			<span className={_className} {...props}>
				<span className="currency__value">{formatValue}</span>
				{this.getCurrency()}
			</span>
		);
	}
}
