/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import "antd/lib/menu/style";
import Menu from "antd/lib/menu";

import "antd/lib/dropdown/style";
import Dropdown from "antd/lib/dropdown";

export default class CompanyComponent extends Component {
	static propTypes = {
		company: PropTypes.array,
		currentCompany: PropTypes.shape({
			name: PropTypes.string
		}),
		currentCompanyId: PropTypes.string,
		onSelectCompany: PropTypes.func
	};

	get overlay() {
		const { company, currentCompanyId } = this.props;

		return (
			<Menu>
				{company.map((item, i) => (
					<Menu.Item
						key={item.id + "_" + i}
						className={cn({ "alert-success": item.id === currentCompanyId })}
						onClick={this.handleSelectCompany(item)}
					>
						<i className="feather icon-airplay p-r-15" />
						&nbsp;{item.name}
					</Menu.Item>
				))}
			</Menu>
		);
	}

	handleStopEvent = e => {
		e?.stopPropagation();
		e?.preventDefault();
	};

	handleSelectCompany = item => ({ domEvent }) => {
		this.handleStopEvent(domEvent);

		const { onSelectCompany } = this.props;
		onSelectCompany({ id: item.id });
	};

	render() {
		const { currentCompany } = this.props;

		return (
			<Dropdown overlay={this.overlay} placement="bottomRight">
				<span className="cursor-pointer" onClick={this.handleStopEvent}>
					<i className="feather icon-monitor p-r-15" />
					{currentCompany?.name}
				</span>
			</Dropdown>
		);
	}
}
