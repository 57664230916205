import React, { Component } from "react";
import "antd/lib/alert/style";
import Alert from "antd/lib/alert";

export default class extends Component {
	static displayName = "Alert";

	render() {
		const { ...props } = this.props;
		return <Alert {...props} />;
	}
}
