import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import NumberInput from "components/ui/NumberInput";
import hocValidateField from "./helpers/hocValidateField";

const ANumberInput = hocValidateField(NumberInput);
const NumberField = props => <Field {...props} component={ANumberInput} />;
/*normalize={parseNumber}*/

NumberField.propTypes = {
	name: PropTypes.string.isRequired,
	float: PropTypes.bool,
	precision: PropTypes.number
};
NumberField.defaultProps = {
	float: false,
	precision: 2
};

export default NumberField;
