import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import Checkbox from "components/ui/Checkbox";
import hocValidateField from "./helpers/hocValidateField";

const ACheckbox = hocValidateField(Checkbox);
const CheckboxField = props => <Field {...props} component={ACheckbox} />;

CheckboxField.propTypes = {
	name: PropTypes.string.isRequired
};

export default CheckboxField;
