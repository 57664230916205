import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ROLES } from "common/enums/user";

import { userInfoSelector, companySelector } from "modules/account/selectors/accountSelector";
import * as action from "../actions/appAction";

import UserComponent from "../components/UserComponent";
import CompanyComponent from "../components/CompanyComponent";
import ControlPanelComponent from "../components/ControlPanelComponent";

@connect(
	state => ({
		userInfo: userInfoSelector(state),
		company: companySelector(state)
	}),
	{
		logout: action.logout.request,
		selectCompany: action.selectCompany.request
	}
)
export default class HeaderContent extends Component {
	static propTypes = {
		logout: PropTypes.func,
		selectCompany: PropTypes.func,
		userInfo: PropTypes.object,
		company: PropTypes.array
	};

	render() {
		const { userInfo, company, logout, selectCompany } = this.props;
		const currentCompany = company?.filter(item => item.id === userInfo?.currentCompanyId)[0];

		const isAdmin = userInfo.userRoles.includes(ROLES.ADMIN);
		const isPreviousAdmin = userInfo.userRoles.includes(ROLES.PREVIOUS_ADMINISTRATOR);

		return (
			<Fragment>
				<ul className="navbar-nav mr-auto">
					{isAdmin && (
						<li>
							<ControlPanelComponent />
						</li>
					)}
					{isPreviousAdmin && (
						<li>
							<a href="/site/control/impersonate-exit" className="text-danger cursor-pointer">
								<i className="feather icon-log-out" />
								&nbsp; Вернуться под администратора
							</a>
						</li>
					)}
				</ul>

				<ul className="navbar-nav ml-auto">
					<li>
						<CompanyComponent
							company={company}
							currentCompany={currentCompany}
							currentCompanyId={userInfo.currentCompanyId}
							onSelectCompany={selectCompany}
						/>
					</li>
					<li>
						<UserComponent userInfo={userInfo} currentCompany={currentCompany} onLogout={logout} />
					</li>
				</ul>
			</Fragment>
		);
	}
}
