import "antd/lib/date-picker/style";

import React, { Component } from "react";
import PropTypes from "prop-types";
import DatePicker from "antd/lib/date-picker";
import moment from "moment";
import cn from "classnames";

const AntDatePickerRange = DatePicker.RangePicker;
const PERIOD = {
	TODAY: "TODAY",
	YESTERDAY: "YESTERDAY",
	LAST_WEEK: "LAST_WEEK",
	WEEK: "WEEK",
	NEXT_WEEK: "NEXT_WEEK",
	LAST_MONTH: "LAST_MONTH",
	MONTH: "MONTH",
	NEXT_MONTH: "NEXT_MONTH",
	LAST_QUARTER: "LAST_QUARTER",
	QUARTER: "QUARTER",
	NEXT_QUARTER: "NEXT_MONTH",
	LAST_YEAR: "LAST_YEAR",
	YEAR: "YEAR",
	NEXT_YEAR: "NEXT_YEAR",
	ALL: "ALL"
};

export default class extends Component {
	static displayName = "DatePickerRange";
	static propTypes = {
		/**
		 * extraPeriod={[
		 * 	"TODAY", "LAST_WEEK", "WEEK", "ALL",
		 * 	{ label: "Custom", value: [moment().startOf("quarter"), moment().endOf("quarter")] }
		 * ]}
		 */
		extraPeriod: PropTypes.arrayOf(
			PropTypes.oneOfType([
				PropTypes.oneOf(Object.values(PERIOD)),
				PropTypes.shape({
					label: PropTypes.string.isRequired,
					value: PropTypes.arrayOf(PropTypes.instanceOf(moment)).isRequired
				})
			])
		),
		value: PropTypes.arrayOf(PropTypes.instanceOf(moment)),
		className: PropTypes.string,
		onChange: PropTypes.func,
		placeholder: PropTypes.arrayOf(PropTypes.string),
		separator: PropTypes.string
	};
	static defaultProps = {
		placeholder: ["От", "До"],
		allowClear: false,
		showToday: false,
		separator: "–", // date delimiter
		format: "DD.MM.YYYY" // display date format
	};

	constructor(props) {
		super(props);

		const [from, to] = props.value;
		this.state = {
			value: [from || null, to || null]
		};
	}

	shouldComponentUpdate(nextProps) {
		const { value } = this.state;
		const [from, to] = this.parseValue(nextProps.value);

		if (!this.isEqualValues(value[0], from) || !this.isEqualValues(value[1], to)) {
			this.setState({ value: [from, to] });
			return false;
		}

		return true;
	}

	isEqualValues = (prev, next) => {
		return !!(prev && next && moment(prev).format() === moment(next).format()) || prev === next;
	};

	parseValue = value => {
		const [_from, _to] = value;
		const from = _from ? moment(_from) : null;
		const to = _to ? moment(_to) : null;

		return [from, to];
	};

	parsePeriod = value => {
		switch (value) {
			case PERIOD.TODAY:
				return {
					label: "Сегодня",
					value: [moment().startOf("day"), moment().endOf("day")]
				};
			case PERIOD.YESTERDAY:
				return {
					label: "Вчера",
					value: [
						moment()
							.subtract(1, "day")
							.startOf("day"),
						moment()
							.subtract(1, "day")
							.endOf("day")
					]
				};

			case PERIOD.LAST_WEEK:
				return {
					label: "Прошлая неделя",
					value: [
						moment()
							.subtract(1, "week")
							.startOf("week"),
						moment()
							.subtract(1, "week")
							.endOf("week")
					]
				};
			case PERIOD.WEEK:
				return {
					label: "Текущая неделя",
					value: [moment().startOf("week"), moment().endOf("week")]
				};
			case PERIOD.NEXT_WEEK:
				return {
					label: "Следующая неделя",
					value: [
						moment()
							.add(1, "week")
							.startOf("week"),
						moment()
							.add(1, "week")
							.endOf("week")
					]
				};

			case PERIOD.LAST_MONTH:
				return {
					label: moment()
						.subtract(1, "month")
						.format("MMMM"),
					value: [
						moment()
							.subtract(1, "month")
							.startOf("month"),
						moment()
							.subtract(1, "month")
							.endOf("month")
					]
				};
			case PERIOD.MONTH:
				return {
					label: moment().format("MMMM"),
					value: [moment().startOf("month"), moment().endOf("month")]
				};
			case PERIOD.NEXT_MONTH:
				return {
					label: moment()
						.add(1, "month")
						.format("MMMM"),
					value: [
						moment()
							.add(1, "month")
							.startOf("month"),
						moment()
							.add(1, "month")
							.endOf("month")
					]
				};

			case PERIOD.LAST_QUARTER:
				return {
					label: "Прошлый квартал",
					value: [
						moment()
							.subtract(1, "quarter")
							.startOf("quarter"),
						moment()
							.subtract(1, "quarter")
							.endOf("quarter")
					]
				};
			case PERIOD.QUARTER:
				return {
					label: "Текущий квартал",
					value: [moment().startOf("quarter"), moment().endOf("quarter")]
				};
			case PERIOD.NEXT_QUARTER:
				return {
					label: "Следующий квартал",
					value: [
						moment()
							.add(1, "quarter")
							.startOf("quarter"),
						moment()
							.add(1, "quarter")
							.endOf("quarter")
					]
				};

			case PERIOD.LAST_YEAR:
				return {
					label: moment()
						.subtract(1, "year")
						.format("YYYY"),
					value: [
						moment()
							.subtract(1, "year")
							.startOf("year"),
						moment()
							.subtract(1, "year")
							.endOf("year")
					]
				};
			case PERIOD.YEAR:
				return {
					label: moment().format("YYYY"),
					value: [moment().startOf("year"), moment().endOf("year")]
				};
			case PERIOD.NEXT_YEAR:
				return {
					label: moment()
						.add(1, "year")
						.format("YYYY"),
					value: [
						moment()
							.add(1, "year")
							.startOf("year"),
						moment()
							.add(1, "year")
							.endOf("year")
					]
				};

			case PERIOD.ALL:
				return {
					label: "Все время",
					value: [null, null]
				};
		}

		return { label: value?.label, value: value?.value };
	};

	handleChange = value => {
		const { onChange } = this.props;

		this.setState({ value }, () => {
			onChange && onChange(value);
		});
	};

	handleExtraChange = value => () => {
		this?.date?.picker?.setState({ open: false }); // WTF ??? This is Ant.D
		this.handleChange(value);
	};

	renderExtraFooter = () => {
		const { extraPeriod } = this.props;

		return extraPeriod.map(period => {
			const { label, value } = this.parsePeriod(period);
			return (
				<span key={period?.label || period} onClick={this.handleExtraChange(value)}>
					{label}
				</span>
			);
		});
	};

	render() {
		const { extraPeriod, className, ...props } = this.props;
		const { value } = this.state;

		props.renderExtraFooter = extraPeriod?.length ? this.renderExtraFooter : undefined;

		return (
			<AntDatePickerRange
				ref={e => (this.date = e)}
				{...props}
				value={value}
				className={cn("_range", className)}
				onChange={this.handleChange}
			/>
		);
	}
}
