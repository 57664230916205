import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import "antd/lib/menu/style";
import Menu from "antd/lib/menu";

import "antd/lib/dropdown/style";
import Dropdown from "antd/lib/dropdown";

@withRouter
export default class ControlPanelComponent extends Component {
	static propTypes = {
		history: PropTypes.shape({
			push: PropTypes.func
		})
	};

	get overlay() {
		return (
			<Menu>
				<Menu.Item onClick={this.handleMenuClick(`/control-panel/company`)}>
					<i className="feather icon-square p-r-15" />
					Компании
				</Menu.Item>
				<Menu.Item onClick={this.handleMenuClick(`/control-panel/users`)}>
					<i className="feather icon-users p-r-15" />
					Пользователи
				</Menu.Item>
				<Menu.Item onClick={this.handleMenuClick(`/control-panel/kkm`)}>
					<i className="feather icon-list p-r-15" />
					Кассы
				</Menu.Item>
				<Menu.Item onClick={this.handleMenuClick(`/control-panel/registries`)}>
					<i className="feather icon-airplay p-r-15" />
					Реестры
				</Menu.Item>
				<Menu.Item onClick={this.handleMenuClick(`/control-panel/offers`)}>
					<i className="feather icon-file p-r-15" />
					Договор-оферты
				</Menu.Item>
			</Menu>
		);
	}

	handleMenuClick = link => ({ domEvent }) => {
		this.handleStopEvent(domEvent);
		this.props.history.push(link);
	};

	handleStopEvent = e => {
		e?.stopPropagation();
		e?.preventDefault();
	};

	render() {
		return (
			<Dropdown overlay={this.overlay} placement="bottomRight">
				<span className="cursor-pointer" onClick={this.handleStopEvent}>
					<i className="feather icon-settings p-r-15" /> Панель управления
				</span>
			</Dropdown>
		);
	}
}
