export const login = {
	toClient: data => ({
		email: data.email,
		firstName: data.firstName,
		lastName: data.lastName,
		middleName: data.middleName,
		phone: data.phone,
		userType: data.userType || "kassa",
		userRoles: data?.userRoles?.split(", ") || [],
		currentCompanyId: data.currentCompanyId
	})
};
