import React, { Component } from "react";
import PropTypes from "prop-types";
import { SelectField } from "components/form";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import * as action from "../actions/companySelectAction";
import * as selector from "../selectors/companySelectSelector";

@connect(
	(state, { form, name, type }) => ({
		isLoading: selector.isLoadingSelector({ form, name, type })(state),
		options: selector.optionsSelector({ form, name, type })(state),
		defaultValue: formValueSelector(form)(state, name)
	}),
	{
		initCpCompanySelect: action.initCpCompanySelect.request,
		searchCpCompanySelect: action.searchCpCompanySelect.request
	}
)
export default class CompanySelectField extends Component {
	static propTypes = {
		form: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		type: PropTypes.oneOf(["all", "owner", "children"]).isRequired,
		companyId: PropTypes.string,
		defaultValue: PropTypes.string,
		placeholder: PropTypes.string,
		isDisabled: PropTypes.bool,

		isLoading: PropTypes.bool,
		options: PropTypes.array,

		initCpCompanySelect: PropTypes.func,
		searchCpCompanySelect: PropTypes.func
	};

	constructor(props) {
		super(props);

		const { form, name, type, companyId, defaultValue, initCpCompanySelect } = this.props;
		initCpCompanySelect({ form, name, type, companyId, defaultValue });
	}

	componentDidUpdate(prevProps) {
		const { form, name, type, companyId, defaultValue, initCpCompanySelect } = this.props;

		if (prevProps.companyId !== companyId) {
			initCpCompanySelect({ form, name, type, companyId, defaultValue });
		}
	}

	get notFoundContent() {
		const { type, isLoading } = this.props;

		if (isLoading) {
			return <div>загружаем</div>;
		}

		switch (type) {
			case "owner":
				return <div>поиск по компаниям не дал результатов</div>;
			case "children":
				return <div>дочерних компаний не найдено</div>;
			default:
				return <div>компаний не найдено</div>;
		}
	}

	get placeholder() {
		const { type, placeholder } = this.props;

		if (placeholder) {
			return placeholder;
		}

		switch (type) {
			case "owner":
				return "Начните вводить для поиска родительской компании";
			case "children":
				return "Начните вводить для поиска дочерней компании";
			default:
				return "Начните вводить для поиска компании";
		}
	}

	handleSearch = value => {
		const { form, name, type, companyId, searchCpCompanySelect } = this.props;
		value && searchCpCompanySelect({ form, name, type, companyId, value });
	};

	render() {
		const { isLoading, options, isDisabled, type, companyId, ...props } = this.props;
		const _isDisabled = isDisabled || (type === "children" && !companyId);

		return (
			<SelectField
				{...props}
				showSearch
				allowClear
				placeholder={this.placeholder}
				notFoundContent={this.notFoundContent}
				disabled={_isDisabled}
				onSearch={this.handleSearch}
				loading={isLoading}
				options={options}
			/>
		);
	}
}
