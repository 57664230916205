export default api => {
	const control = api.addResource("control");

	// /site/control/...
	control.addResource("companyUserLink", "company-user-link");
	control.addResource("companyUserUnlink", "company-user-unlink");
	control.addResource("offer");

	const registry = control.addResource("registry");
	registry.addResource("lines");

	const kkm = control.addResource("kkm");
	kkm.addResource("mode");
	kkm.addResource("transaction");

	const company = control.addResource("company");
	company.addResource("childCompanies", "child-companies");
	company.addResource("users");
	company.addResource("kkm");
	company.addResource("owner");

	const user = control.addResource("user");
	user.addResource("password");
	user.addResource("company");
	user.addResource("type");
};
